import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environment/environment';
import {Adresse} from '@utils/dto/user/adresse';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdresseService {
  private endpoint: string = environment.api + '/adresses';

  constructor(private http: HttpClient) {}

  create(adresse: Adresse): Observable<Adresse> {
    return this.http.post<Adresse>(this.endpoint + '/create', adresse);
  }

  getDomicile(): Observable<Adresse> {
    return this.http.get<Adresse>(this.endpoint + '/domicile');
  }

  getLastHomeAdresse(): Observable<Adresse> {
    return this.http.get<Adresse>(this.endpoint + '/last-home');
  }

  findAll(): Observable<Adresse[]> {
    return this.http.get<Adresse[]>(this.endpoint);
  }
}
