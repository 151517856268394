import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {TranslocoService} from '@ngneat/transloco';

@Pipe({
  name: 'dateAgo',
  pure: true,
})
export class DateAgoPipe extends DatePipe implements PipeTransform {
  constructor(private _translator: TranslocoService, @Inject(LOCALE_ID) private _locale: string) {
    super(_locale, null);
  }

  override transform(value: any, args?: any): any {
    if (value) {
      const seconds: number = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) {
        // less than 30 seconds ago will show as 'Just now'
        return this._translator.translate('date.now');
      }
      const intervals: {[key: string]: number} = {
        // year: 31536000,
        // month: 2592000,
        // week: 604800,
        // day: 86400,
        // hour: 3600,
        minute: 60,
        second: 1,
      };
      let counter: number;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        // break at 30 minutes
        if (counter > 30 && i === 'minute') {
          return super.transform(value, args);
        }
        if (counter > 0) {
          const interval: string = this._translator.translate('date.interval.' + i);
          if (counter === 1) {
            return this._translator.translate('date.before.sing', {count: counter, interval});
          } else {
            return this._translator.translate('date.before.plur', {count: counter, interval});
          }
        }
      }
    }
    return value;
  }
}
