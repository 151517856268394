import {Injectable} from '@angular/core';
import {Preferences} from '@capacitor/preferences';

export type StorageKeyType = StorageKeyEnum | string;

export enum StorageKeyEnum {
  LAST_SYNC = 'LAST_SYNC',
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  has(key: StorageKeyType): Promise<boolean> {
    return Preferences.keys().then(res => {
      return res.keys.indexOf(key) !== -1;
    });
  }

  get<T>(key: StorageKeyType, defaultValue?: T): Promise<T> {
    return Preferences.get({key}).then(res => {
      if (res?.value) {
        return JSON.parse(res.value);
      }
      return defaultValue;
    });
  }

  getMultiple<T>(keys: StorageKeyType[], defaultValue?: Map<StorageKeyType, T>): Promise<Map<StorageKeyType, T>> {
    const map: Map<StorageKeyType, T> = new Map<StorageKeyType, T>();
    if (!keys?.length) {
      return Promise.reject('No keys passed');
    }
    const promises: Promise<void>[] = [];
    for (const key of keys) {
      promises.push(
        this.get(key, defaultValue?.get(key)).then(res => {
          map.set(key, res);
        })
      );
    }
    return Promise.all(promises).then(() => map);
  }

  set(key: StorageKeyType, value: unknown): Promise<void> {
    if (!value) {
      return Promise.reject('No value passed');
    }
    return Preferences.set({
      key,
      value: JSON.stringify(value),
    });
  }

  setMultiple(values: Map<StorageKeyType, unknown>): Promise<void> {
    if (!values) {
      return Promise.reject('No value passed');
    }
    const promises: Promise<void>[] = [];
    values.forEach((value, key) => {
      promises.push(this.set(key, value));
    });
    return Promise.all(promises).then(() => {
      return;
    });
  }

  /**
   * Push un élément dans une array ou dans une array d’un objet du stockage
   * Ex 1 : Push un élément dans une array
   * const toPush = {elem_a_push};
   * Avant de push : KEY = [{elem_deja_present}];
   * Méthode : pushInArray(KEY, {elem_a_push});
   * Après push : KEY = [{elem_deja_present}, {elem_a_push}];
   *
   * Ex 2 : Push dans une array d’un objet du stockage
   * const toPush = 'a_push';
   * Avant de push : KEY = {sousObj: {arrayAPushDedans : ['deja_push']}};
   * Méthode : pushInArray(KEY, 'a_push', 'sousObj.arrayAPushDedans');
   * Après push : KEY = {sousObj: {arrayAPushDedans : ['deja_push', 'a_push]}};
   */
  pushInArray<T>(key: StorageKeyType, value: unknown | T, path: string = ''): Promise<void> {
    if (!value) {
      return Promise.reject('No value passed');
    }
    if (path) {
      return this.get<T>(key).then(res => {
        const keys: string[] = path.split('.');
        let tmp: any = res;
        for (const element of keys) {
          tmp = tmp[element];
        }
        tmp.push(value);
        return Preferences.set({
          key,
          value: JSON.stringify(res),
        });
      });
    } else {
      return this.get<T[]>(key).then(res => {
        res.push(value as T);
        return Preferences.set({
          key,
          value: JSON.stringify(res),
        });
      });
    }
  }

  /**
   * Met à jour un élément (comme un set) ou met à jour une valeur d’un element stocké (via le path)
   * Ex 1 : Met à jour un élément
   * const toSet = {elem_a_set};
   * Avant de push : KEY = {elem_deja_present};
   * Méthode : update(KEY, {elem_a_set});
   * Après push : KEY = {elem_a_set};
   *
   * Ex 2 : Met à jour une valeur d’un element stocké
   * const toSet = 'a_set';
   * Avant de push : KEY = {sousObj: {elemAUpdate : 'deja_set'}};
   * Méthode : update(KEY, 'a_set', 'sousObj.elemAUpdate');
   * Après push : KEY = {sousObj: {elemAUpdate : 'a_set'}};
   * @param key
   * @param value
   * @param path
   */
  update<T>(key: StorageKeyType, value: unknown | T, path: string = ''): Promise<void> {
    if (!value) {
      return Promise.reject('No value passed');
    }
    if (path) {
      return this.get<T>(key).then(res => {
        const keys: string[] = path.split('.');
        const tmp: any = res;
        this._deepAllocationValue(tmp, keys, value);
        return Preferences.set({
          key,
          value: JSON.stringify(res),
        });
      });
    } else {
      return this.set(key, value);
    }
  }

  /**
   * Supprime tout le stockage, quelques clés ou tout mais en gardant quelques clés.
   * @param toClear supprimer que quelques clés
   * @param toKeep supprimer tout mais garder quelques clés
   */
  async clear(toClear?: StorageKeyType | StorageKeyType[], toKeep?: StorageKeyType | StorageKeyType[]): Promise<void> {
    if (toClear) {
      if (Array.isArray(toClear)) {
        const promises: Promise<void>[] = [];
        toClear.forEach(key => promises.push(Preferences.remove({key})));
        return Promise.all(promises).then(() => {
          return;
        });
      } else {
        return Preferences.remove({key: toClear});
      }
    } else {
      if (toKeep) {
        if (Array.isArray(toKeep)) {
          const getPromises: Map<StorageKeyType, unknown> = await this.getMultiple(toKeep);
          await Preferences.clear();
          await this.setMultiple(getPromises);
        } else {
          const value: unknown = await this.get(toKeep);
          await Preferences.clear();
          await this.set(toKeep, value);
        }
      } else {
        await Preferences.clear();
      }
    }
  }

  private _deepAllocationValue(obj: any, keys: string[], value: unknown, idx: number = 0): unknown {
    if (keys.length === idx) {
      return value;
    }
    return (obj[keys[idx]] = this._deepAllocationValue(obj, keys, value, idx + 1));
  }
}
