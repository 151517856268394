<div class="footer-spacing"></div>
<div class="footer d-flex justify-content-between align-self-center align-items-center">
  <button mat-icon-button
          [disableRipple]="true"
          (click)="account()"
          [class.active]="router.url.includes('account')"
          [color]="router.url.includes('account')  ? 'accent' : null">
    <mat-icon class="icon">person</mat-icon>
  </button>
  <button mat-icon-button
          [disableRipple]="true"
          [navigation]="['/']"
          [class.active]="['/patient', '/preleveur'].includes(router.url)"
          [color]="['/patient', '/preleveur'].includes(router.url)  ? 'accent' : null">
    <mat-icon class="icon">home</mat-icon>
  </button>
  <button mat-icon-button
          [disableRipple]="true"
          (click)="notification()"
          [class.active]="router.url.includes('notification')"
          [color]="router.url.includes('notification')  ? 'accent' : null">
    <mat-icon class="icon"
              matBadge="&#8288;"
              matBadgeSize="small"
              matBadgeColor="warn"
              [matBadgeHidden]="!notifService.isNew">notifications</mat-icon>
  </button>
</div>
