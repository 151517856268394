import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Labo} from '@utils/dto/labo/labo';

@Component({
  selector: 'app-lab-item',
  templateUrl: './lab-item.component.html',
  styleUrls: ['./lab-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabItemComponent implements OnInit {
  @Input() lab!: Labo;
  @Input() last?: boolean;

  @Output() buttonClick: EventEmitter<unknown> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
