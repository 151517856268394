import {Directive, HostListener} from '@angular/core';
import {NavigationService} from '@utils/service/navigation.service';

@Directive({
  selector: '[navBack]',
})
export class NavBackDirective {
  constructor(private nav: NavigationService) {}

  @HostListener('click')
  onClick(): boolean {
    this.nav.back();
    // return false to prevent Default (useful for link)
    return false;
  }
}
