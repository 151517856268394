import {ChangeDetectionStrategy, Component} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {UserInfo} from '@security/user-info';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends UserInfo {
  constructor(public override keycloak: KeycloakService, public dialog: MatDialog) {
    super(keycloak);
  }

  logout(): void {
    const dialogRef: MatDialogRef<LogoutDialog> = this.dialog.open(LogoutDialog);

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.keycloak.logout();
      }
    });
  }
}

@Component({
  selector: 'logout-dialog',
  templateUrl: 'logout-dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutDialog {}
