<div class="header">
  <div class="d-flex justify-content-between">
    <button class="redirect-button" *ngIf="redirect && !back" [navigation]="[redirect]" mat-icon-button color="accent">
      <mat-icon>arrow_back</mat-icon>
    </button>

    <button class="redirect-button" *ngIf="!redirect && !back" (click)="buttonClick.emit()" mat-icon-button
            color="accent">
      <mat-icon>arrow_back</mat-icon>
    </button>

    <button class="redirect-button" *ngIf="back" navBack mat-icon-button color="accent">
      <mat-icon>arrow_back</mat-icon>
    </button>

    <button *ngIf="actionButton" class="action-button" mat-button color="accent" (click)="actionClick.emit()">
      <p class="m-0" [style.padding-top]="'5px'">{{actionText | transloco}}</p>
    </button>
  </div>

  <h1 *ngIf="titre">{{translate ? (titre | transloco:args) : titre}}</h1>
</div>
