import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent {
  /**
   * Title of the section
   */
  @Input() titre!: string;
  /**
   * If the title should be translate or not
   *
   * default : true
   */
  @Input() translate: boolean = true;

  /**
   * alternate background color
   */
  @Input() alternate: boolean = false;

  constructor() {}
}
