import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from '@page/login/login/login.component';
import {LogoutComponent} from '@page/login/logout/logout.component';
import {NotFoundComponent} from '@page/not-found/not-found.component';
import {RedirectComponent} from '@page/redirect/redirect.component';
import {AuthenticatedGuard} from '@security/authenticated.guard';
import {RedirectGuard} from '@security/redirect.guard';
import {ProfileEnum} from '@utils/enums/profile.enum';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/redirect',
    pathMatch: 'full',
  },
  {
    path: 'redirect',
    component: RedirectComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'register',
    loadChildren: () => import('@page/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'disclaimer',
    loadChildren: () => import('@page/disclaimer/disclaimer.module').then(m => m.DisclaimerModule),
  },
  {
    path: 'password',
    loadChildren: () => import('@page/password/password.module').then(m => m.PasswordModule),
  },
  {
    path: '',
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: 'patient',
        canActivate: [RedirectGuard],
        data: {roles: [ProfileEnum.PATIENT]},
        loadChildren: () => import('@page/patient/patient.module').then(m => m.PatientModule),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: 'preleveur',
        canActivate: [RedirectGuard],
        data: {roles: [ProfileEnum.PRELEVEUR]},
        loadChildren: () => import('@page/preleveur/preleveur.module').then(m => m.PreleveurModule),
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
