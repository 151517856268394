import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Geolocation, Position} from '@capacitor/geolocation';
import {AdresseService} from '@service/adresse.service';
import {GeoApiService} from '@service/geoapi.service';
import {LaboService} from '@service/labo.service';
import {NurseService} from '@service/nurse.service';
import {Labo} from '@utils/dto/labo/labo';
import {Nurse} from '@utils/dto/nurse/nurse';
import {Adresse, AdresseApi, Coord, SearchType} from '@utils/dto/user/adresse';
import {NavigationService} from '@utils/service/navigation.service';
import {Observable, Subject} from 'rxjs';
import {debounceTime, take, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-search-location',
  templateUrl: './search-location.component.html',
  styleUrls: ['./search-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchLocationComponent implements OnInit, AfterViewInit {
  @ViewChild('input') input!: ElementRef;
  position: FormControl = new FormControl('');
  input$: Subject<string> = new Subject<string>();
  autosuggest$!: Observable<AdresseApi[]>;

  domicile?: Adresse;

  mode!: 'lab' | 'register' | 'nurse';
  foundLabs: Labo[] = [];
  foundNurses: Nurse[] = []
  loading: boolean = false;

  constructor(
    private _geoapi: GeoApiService,
    private _laboService: LaboService,
    private _adrService: AdresseService,
    private _nav: NavigationService,
    private _nurseService: NurseService,
    private _route: ActivatedRoute,
    private _cd: ChangeDetectorRef
  ) {
    if (_nav.getUrl().includes('lab')){
      this.mode = 'lab';
    }else if (_nav.getUrl().includes('annuaire')) {
      this.mode = 'nurse';
    } else {
      this.mode = 'register'
    }
    if (this.mode === 'lab' || this.mode === 'nurse') {
      this._adrService
        .getDomicile()
        .pipe(take(1))
        .subscribe(home => {
          this.domicile = home;
          this._cd.detectChanges();
        });
    }
  }

  ngOnInit(): void {
    this.autosuggest$ = this.input$.pipe(
      debounceTime(500),
      switchMap(text => this._geoapi.search(text, this.mode === 'lab' || this.mode === 'nurse' ? SearchType.MUNICIPALITY : SearchType.HOUSENUMBER))
    );
  }

  ngAfterViewInit(): void {
    this.input?.nativeElement?.focus();
    this._cd.detectChanges();
  }

  select(adresse: AdresseApi): void {
    this.loading = true;
    this.input$.next(adresse.properties.name + ' - ' + adresse.properties.postcode);
    const coords: Coord = AdresseApi.getCoord(adresse);
    if (this.mode !== 'nurse') {
      this.findLabs(coords.latitude, coords.longitude);
    }
   else {
     this.findNurses(coords.latitude, coords.longitude);
    }
  }

  selectHome(): void {
    if (this.domicile) {
      this.loading = true;
      const adr: string[] = [this.domicile.adr1, this.domicile.cp, this.domicile.ville];
      this._geoapi
        .search(adr.join(','), SearchType.HOUSENUMBER)
        .pipe(take(1))
        .subscribe(res => {
          if (res.length) {
            this.select(res[0]);
          } else {
            if (this.mode !== 'nurse') {
              this.foundLabs = [];
            }
            else {
              this.foundNurses = [];
            }
            this.loading = false;
          }
        });
    }
  }

  async currentPosition(): Promise<void> {
    this.loading = true;
    const coordinates: Position = await Geolocation.getCurrentPosition();
    if (this.mode !== 'nurse') {
      this.findLabs(coordinates.coords.latitude, coordinates.coords.longitude);
    }
    else {
      this.findNurses(coordinates.coords.latitude, coordinates.coords.longitude);
    }
  }

  findLabs(latitude: number, longitude: number): void {
    this._laboService.findNearest(latitude, longitude).subscribe(result => {
      this.foundLabs = result;
      this.loading = false;
      this._cd.detectChanges();
    });
  }

  findNurses(latitude: number, longitude: number): void {
    this._nurseService.findNearest(latitude, longitude).subscribe(result => {
      this.foundNurses = result;
      this.loading = false;
      this._cd.detectChanges();
    });
  }

  getOptionText(adresse: AdresseApi): string {
    return this.mode === 'lab' || this.mode === 'nurse'
      ? adresse.properties.name + ' - ' + adresse.properties.postcode
      : adresse.properties.name + ', ' + adresse.properties.postcode + ' ' + adresse.properties.city;
  }

  resetField(): void {
    this.position.setValue('');
    this.input$.next('');
    this.foundLabs = [];
    this.foundNurses = [];
  }

  getDetails(id: string | undefined): void {
    if (id) {
      this._nav.navigate(['../detail', id], {relativeTo: this._route});
    }
  }

  redirectToMap(): void {
    this._nav.navigate(['../map'], {relativeTo: this._route});
  }
}
