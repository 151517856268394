import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {environment} from '@environment/environment';
import {UserNameIppResponse, UserResponse} from '@response/user.response';
import {Adresse} from '@utils/dto/user/adresse';
import {DayEnum, PreleveurSchedule} from '@utils/dto/user/preleveur-schedule';
import {PreleveurScheduleException} from '@utils/dto/user/preleveur-schedule-exception';
import {NO_SNACKBAR_CONTEXT} from '@utils/interceptor/error.interceptor';
import {ResultDiffusion} from '@utils/dto/user/result-diffusion';
import {firstValueFrom, map, Observable, of} from 'rxjs';
import {UserCreateRequest, UserTempRequest} from '../utils/dto/user/user';
import {ProfileEnum} from '../utils/enums/profile.enum';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private endpoint: string = environment.api + '/users';
  private publicEndpoint: string = environment.api + '/public/users';

  constructor(private http: HttpClient) {}

  get(): Observable<UserResponse> {
    return this.http.get(this.endpoint);
  }

  getByIpp(ipp: string): Observable<UserNameIppResponse> {
    return this.http.get(this.endpoint + '/' + ipp, {context: NO_SNACKBAR_CONTEXT});
  }

  getAssociatedPreleveur(): Observable<UserResponse> {
    return this.http.get(this.endpoint + '/associated-preleveur');
  }
  create(user: UserCreateRequest): Observable<UserResponse> {
    return this.http.post(this.publicEndpoint + '/register/create', user);
  }

  validate(hash: string): Observable<boolean> {
    const params: HttpParams = new HttpParams().set('hash', hash);
    return this.http.post<boolean>(this.publicEndpoint + '/register/validate', null, {params});
  }

  updateTemp(user: UserTempRequest): Observable<boolean> {
    return this.http.post<boolean>(this.publicEndpoint + '/register/temp', user);
  }

  resendValidation(id: string, profile: ProfileEnum): Observable<boolean> {
    const params: HttpParams = new HttpParams().set('id', id).set('profile', profile);
    return this.http.get<boolean>(this.publicEndpoint + '/register/resend', {params});
  }

  /**
   * Request a password change
   * @param username
   * @returns
   */
  requestPwdReset(username: string): Observable<boolean> {
    const params: HttpParams = new HttpParams().set('username', username);
    return this.http.post<boolean>(this.publicEndpoint + '/password/request', null, {params});
  }

  /**
   * Change password
   * @param hash
   * @param password
   * @returns
   */
  changePassword(hash: string, password: string): Observable<boolean> {
    return this.http.post<boolean>(this.publicEndpoint + '/password/change', {
      hash,
      password,
    });
  }

  isFirstLogin(): Observable<boolean> {
    return this.http.get<boolean>(this.endpoint + '/first-login');
  }

  updateFirstLogin(): Observable<unknown> {
    return this.http.post(this.endpoint + '/first-login', null);
  }

  updateLaboFavorite(laboId: string | undefined): Observable<unknown> {
    let params: HttpParams = new HttpParams();
    if (laboId) {
      params = params.set('laboId', laboId);
    }
    return this.http.post(this.endpoint + '/labo-favorite', null, {params});
  }

  checkPhone(phone: string): Observable<boolean> {
    if (phone) {
      phone = phone.replace(/\s+/g, ''); // remove space before sending it
    }
    const params: HttpParams = new HttpParams().set('phone', phone);
    return this.http.get<boolean>(this.publicEndpoint + '/check-phone', {params});
  }

  checkEmail(email: string): Observable<boolean> {
    const params: HttpParams = new HttpParams().set('email', email);
    return this.http.get<boolean>(this.publicEndpoint + '/check-email', {params});
  }

  updateAdresses(adresses: Adresse[]): Observable<UserResponse> {
    return this.http.put<UserResponse>(this.endpoint + '/adresses', adresses);
  }

  updateEmail(email: string): Observable<UserResponse> {
    return this.http.put<UserResponse>(this.endpoint + '/email', email);
  }

  updatePhone(phone: string): Observable<UserResponse> {
    return this.http.put<UserResponse>(this.endpoint + '/phone', phone);
  }

  updateSecu(secu: string): Observable<UserResponse> {
    return this.http.put<UserResponse>(this.endpoint + '/secu', secu);
  }

  updateResultDiffusion(resultDiffusion: ResultDiffusion): Observable<UserResponse> {
    return this.http.put<UserResponse>(this.endpoint + '/resultDiffusion', resultDiffusion);
  }

  updateNotification(notificationsEnabled: boolean): Observable<UserResponse> {
    return this.http.put<UserResponse>(this.endpoint + '/notification', notificationsEnabled);
  }

  getScheduleByDay(day: DayEnum): Observable<PreleveurSchedule[]> {
    const params: HttpParams = new HttpParams().set('day', day);
    return this.http.get<PreleveurSchedule[]>(this.endpoint + '/schedule', {params});
  }

  saveScheduleByDay(day: DayEnum, schedules: PreleveurSchedule[]): Observable<PreleveurSchedule[]> {
    const params: HttpParams = new HttpParams().set('day', day.toString().toUpperCase());
    return this.http.put<PreleveurSchedule[]>(this.endpoint + '/schedule', schedules, {params});
  }

  getScheduleExceptionByDate(date: Date): Observable<PreleveurScheduleException[]> {
    date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
    const date_str: string = date.toISOString().split('T')[0];
    return this.http.get<PreleveurScheduleException[]>(this.endpoint + '/exception/' + date_str);
  }

  getListDateScheduleExceptions(): Observable<Date[]> {
    return this.http.get<Date[]>(this.endpoint + '/exception');
  }

  saveScheduleExceptionByDate(date: Date, exception: PreleveurScheduleException[]): Observable<PreleveurScheduleException> {
    date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
    const date_str: string = date.toISOString().split('T')[0];
    const params: HttpParams = new HttpParams().set('date', date_str);
    return this.http.put(this.endpoint + '/exception', exception, {params});
  }

  async deleteScheduleException(date: Date): Promise<void> {
    const params: HttpParams = new HttpParams().set('date', date.toString());
    await firstValueFrom(this.http.delete(this.endpoint + '/exception', {params}));
  }

  deletePreleveurAssociation(): Observable<UserResponse>{
    return this.http.delete(this.endpoint + '/dissociate-preleveur');
  }

  getAssociatedPatients(): Observable<UserResponse[]> {
    return this.http.get<UserResponse[]>(this.endpoint + '/associated-patients');
  }

  associatePreleveurById(id: string): Observable<Object> {
    return this.http.put(this.endpoint + '/associate-by-id',id);
  }

  getPreleveurById(id: string): Observable<UserResponse> {
    return this.http.get(this.endpoint + '/preleveur/' + id, {context: NO_SNACKBAR_CONTEXT});
  }

  // VALIDATORS //

  phoneValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value) {
        return this.checkPhone(control.value).pipe(
          map(res => {
            // if res is true, username exists, return true
            return !res ? {phoneExists: true} : null;
            // NB: Return null if there is no error
          })
        );
      } else {
        return of(null);
      }
    };
  }

  emailValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value) {
        return this.checkEmail(control.value).pipe(
          map(res => {
            // if res is true, username exists, return true
            return !res ? {emailExists: true} : null;
            // NB: Return null if there is no error
          })
        );
      } else {
        return of(null);
      }
    };
  }
}
