import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environment/environment';
import {DocumentDto} from '@utils/dto/document/document';
import {DocumentTypeEnum} from '@utils/enums/document-type.enum';
import {firstValueFrom, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private _endpoint: string = environment.api + '/documents';

  constructor(private _http: HttpClient) {}

  upload(file: File, documentType: DocumentTypeEnum, temporary: boolean = false, sampleRef?: string): Observable<DocumentDto> {
    const doc: DocumentDto = {
      documentType: documentType,
      temporary: temporary,
      sampleRef,
    };

    return this.uploadFile(file, doc);
  }

  uploadFile(file: File, doc: DocumentDto): Observable<DocumentDto> {
    const docBlob: Blob = new Blob([JSON.stringify(doc)], {type: 'application/json'});

    const formData: FormData = new FormData();
    formData.append('documentDto', docBlob, 'document.json');
    formData.append('multipartFile', file, file.name);

    const headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this._http.post<DocumentDto>(this._endpoint, formData, {headers});
  }

  get(fileId: string): Observable<Blob> {
    return this._http.get(this._endpoint + `/${fileId}`, {responseType: 'blob'});
  }

  delete(fileId: string): void {
    firstValueFrom(this._http.delete(this._endpoint + `/${fileId}`));
  }

  getUserDocumentList(sampleRef?: string): Observable<DocumentDto[]> {
    let params: HttpParams = new HttpParams();
    if (sampleRef) {
      params = params.set('sampleRef', sampleRef);
    }
    return this._http.get<DocumentDto[]>(this._endpoint, {params});
  }

  isUpToDate(userId: string): Observable<boolean> {
    const params: HttpParams = new HttpParams().set('userId', userId);
    return this._http.get<boolean>(this._endpoint + '/is-up-to-date', {params});
  }
}
