<div class="notif d-flex align-items-center m-0 position-relative" [class.last]="last">
  <div *ngIf="isNew" class="new align-self-baseline">&NoBreak;</div>
  <div class="flex-grow-1 px-3 text">
    <span>{{notification.notification?.message}}</span>
    <br>
    <div *ngIf="notification.data?.type === SampleStatusEnum.REFUSED && notification.data?.oldSample">
      <button mat-button class="link px-1" (click)="reschedule()">{{'patient.notification.reschedule' |
        transloco}}</button>
      <br>
    </div>
    <span class="date">{{notification.sentAt | dateAgo:('date.format.full' | transloco) }}</span>
  </div>
  <div class="icon flex-shrink-1 ">
    <img *ngIf="notification.data?.type === SampleStatusEnum.CONFIRM" src="assets/svg/confirm.svg">
    <img *ngIf="notification.data?.type === SampleStatusEnum.UNDERWAY" src="assets/svg/on-the-way.svg">
    <img *ngIf="notification.data?.type === SampleStatusEnum.REFUSED || notification.data?.type === SampleStatusEnum.CANCEL"
         src="assets/svg/cancelled.svg">
    <img *ngIf="notification.data?.type === 'count'" src="assets/svg/count.svg">
    <img *ngIf="notification.data?.type === 'reminder'" src="assets/svg/reminder.svg">
  </div>
</div>
