import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {UserResponse} from '@response/user.response';
import {AdresseService} from '@service/adresse.service';
import {UserService} from '@service/user.service';
import {AdresseFormComponent} from '@utils/component/adresse-form/adresse-form.component';
import {Adresse} from '@utils/dto/user/adresse';
import {NavigationService} from '@utils/service/navigation.service';
import {firstValueFrom, Observable, take} from 'rxjs';

@Component({
  selector: 'app-account-update-addresses',
  templateUrl: './account-update-addresses.component.html',
  styleUrls: ['./account-update-addresses.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AccountUpdateAddressesComponent implements OnInit {
  formArray!: FormArray;
  addresses!: Adresse[];
  nbForm: number[] = [];
  mode!:  'patient' | 'preleveur'
  constructor(private _route: ActivatedRoute, private _nav: NavigationService, private _userService: UserService) {}

  ngOnInit(): void {
    this.formArray = new FormArray([] as never);
    this._userService
      .get()
      .pipe(take(1))
      .subscribe(user => {
        if (user.profile === 'PATIENT') {
          this.mode = 'patient';
        } else {
          this.mode = 'preleveur';
        }
        if (user?.adresses) {
          this.addresses = this.mode === 'patient' ? user.adresses : [user.adresses.filter(adr => adr.domicile === true)[0]];
          this.addAdr(this.addresses.length);
          this.addresses.sort((a, b) => Number(b.domicile) - Number(a.domicile));
          setTimeout(() => this.formArray.setValue(Adresse.toFormValues(this.addresses)), 0);
        }
      });
  }

  removeAdr(index: number): void {
    this.formArray.removeAt(index + 1);
    this.nbForm.splice(index + 1, 1);
  }

  addAdr(nb: number = 1): void {
    for (let i: number = 0; i < nb; i++) {
      // use to always keep unique value in the array
      // otherwise angular mess up with display
      this.nbForm.push((this.nbForm[this.nbForm.length - 1] | 0) + 1);
    }
  }

  async validate(): Promise<void> {
    if (this.formArray.valid) {
      const adr: Adresse[] = Adresse.fromFormValues(this.formArray.value);
      adr[0].domicile = true;
      await firstValueFrom(this._userService.updateAdresses(adr));
      this._nav.navigate(['..'], {relativeTo: this._route});
    } else {
      this.formArray.markAllAsTouched();
    }
  }
}
