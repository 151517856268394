/* eslint-disable @typescript-eslint/no-explicit-any */
import {Injectable} from '@angular/core';
import {Navigation, NavigationBehaviorOptions, NavigationExtras, Router, UrlTree} from '@angular/router';
import {Routing} from '@utils/dto/routing';
import {cloneDeep, last} from 'lodash';

@Injectable({providedIn: 'root'})
export class NavigationService {
  private stopPage: RegExp = /^\/(patient|preleveur|login)?$/g;

  private history: Routing[] = [];

  constructor(private router: Router) {
    // init last back to home page
    this.history.push({url: '/'});
  }

  getCurrentNavigation(): Navigation | null {
    return this.router.getCurrentNavigation();
  }

  getUrl(): string {
    return this.router.url;
  }

  createUrl(commands: any[], extras?: NavigationExtras | undefined): string {
    const url: UrlTree = this.router.createUrlTree(commands, extras);
    return url.toString();
  }

  navigate(commands: any[], extras?: NavigationExtras | undefined): void {
    const url: UrlTree = this.router.createUrlTree(commands, extras);
    this.navigateByUrl(url, extras);
  }

  navigateByUrl(url: string | UrlTree, extras?: NavigationBehaviorOptions | undefined): void {
    if (url.toString().match(this.stopPage)) {
      this.history = [];
    }
    this.history.push({url, extras});

    this.router.navigateByUrl(url, extras);
  }

  /**
   * Method to insert navigation in history
   *
   * @param urlToInsert
   * @param replaceCurrent (default: true) if current nav is replace by given routes
   */
  rewriteNav(urlToInsert: Routing[], replaceCurrent: boolean = true): void {
    if (replaceCurrent) {
      this.history.pop();
    }
    this.history = this.history.concat(urlToInsert);
  }

  replaceCurrentDataState(newData: any): void {
    const current: Routing | undefined = this.history.pop();
    if (current) {
      if (current?.extras?.state) {
        // clone deep to avoid keeping reference
        current.extras.state['data'] = cloneDeep(newData);
      }
      this.history.push(current);
    }
  }

  back(): Promise<boolean> {
    // remove current route
    this.history.pop();
    const previous: Routing | undefined = last(this.history);
    if (previous) {
      return this.router.navigateByUrl(previous?.url, previous?.extras);
    } else {
      return this.router.navigateByUrl('/');
    }
  }

  /**
   *
   * @returns true if you can go back in history
   */
  canGoBack(): boolean {
    return this.history.length > 1;
  }
}
