import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environment/environment';
import {Labo} from '@utils/dto/labo/labo';
import {FixedSchedule} from '@utils/dto/labo/schedule';
import {LaboSearch} from '@utils/dto/utils/search';
import {Searchable} from '@utils/dto/utils/searchable.interface';
import {map, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LaboService {
  private endpoint: string = environment.api + '/labos';
  private publicEndpoint: string = environment.api + '/public/labos';

  constructor(private http: HttpClient) {}

  get(uuid: string): Observable<Labo> {
    return this.http.get(this.publicEndpoint + '/' + uuid);
  }

  search(search: LaboSearch): Observable<Labo[]> {
    const params: HttpParams = new HttpParams().set('q', search.convertToRequest().join(','));
    return this.http.get<Searchable<Labo>>(this.publicEndpoint, {params}).pipe(map((result: Searchable<Labo>): Labo[] => result.content));
  }

  /**
   * Find lab sort by distance from coord
   * @param lat
   * @param lon
   * @param max optional - max distance in meter
   * @returns list of labo (empty list if no labo found)
   */
  findNearest(lat: string | number, lon: string | number, max?: number): Observable<Labo[]> {
    let params: HttpParams = new HttpParams().set('lat', lat.toString()).set('lon', lon.toString());
    if (max) {
      params = params.set('max', max);
    }
    return this.http.get<Labo[]>(this.publicEndpoint + '/find-nearest', {params});
  }

  getLastVisited(): Observable<Labo[]> {
    return this.http.get<Labo[]>(this.endpoint + '/last-visited');
  }

  findLabByCover(lat: string | number, lon: string | number): Observable<Labo> {
    const params: HttpParams = new HttpParams().set('lat', lat.toString()).set('lon', lon.toString());
    return this.http.get<Labo>(this.endpoint + '/cover', {params});
  }

  findPreleveurLab(): Observable<Labo[]> {
    return this.http.get<Labo[]>(this.endpoint + '/all');
  }

  getFixedSchedule(laboId: string): Observable<FixedSchedule[]> {
    return this.http.get<FixedSchedule[]>(this.endpoint + "/" + laboId + "/fixed-schedule");
  }
}
