import {Injectable} from '@angular/core';
import {ConnectionStatus, Network} from '@capacitor/network';
import {Toast} from '@capacitor/toast';
import {TranslocoService} from '@ngneat/transloco';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnlineService {
  /**
   * Souscrire à ce subject permet de détecter un changement d’état de connexion (Passage de connecté à déconnecté et inversement)
   */
  online: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private _translator: TranslocoService) {
    // Ajouter un listener sur le changement de status
    Network.addListener('networkStatusChange', status => {
      console.info('Network status changed', status);
      if (this.statusValue?.connected && !status.connected) {
        // Si on passe de connecté à déconnecté
        Toast.show({
          position: 'bottom',
          text: _translator.translate('app.network.offline'),
        });
      } else if (!this.statusValue?.connected && status.connected) {
        // Si on passe de déconnecté à connecté
        Toast.show({
          position: 'bottom',
          text: _translator.translate('app.network.online'),
        });
      }
      this.status.next(status);
      if (this.online.getValue() !== status.connected) {
        this.online.next(status.connected); // set l’état de connexion
      }
    });

    // Récupérer le status actuel au lancement de l’app
    this.getStatus();
  }

  status: BehaviorSubject<ConnectionStatus | undefined> = new BehaviorSubject<ConnectionStatus | undefined>(undefined);

  get statusValue(): ConnectionStatus | undefined {
    return this.status.getValue();
  }

  getStatus(): Promise<ConnectionStatus> {
    return Network.getStatus().then(status => {
      this.status.next(status);
      // Si l’état de connexion change
      if (this.online.getValue() !== status.connected) {
        this.online.next(status.connected); // set l’état de connexion
      }
      return status;
    });
  }
}
