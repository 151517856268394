import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Nurse} from '@utils/dto/nurse/nurse';

@Component({
  selector: 'app-nurse-item',
  templateUrl: './nurse-item.component.html',
  styleUrls: ['./nurse-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NurseItemComponent implements OnInit {

  @Input() nurse!: Nurse;
  @Input() last?: boolean;

  @Output() buttonClick: EventEmitter<unknown> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
  }

}
