import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleHeaderComponent {
  @Input() titre?: string;
  @Input() translate: boolean = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() args?: any;
  @Input() redirect?: string;
  @Input() back?: boolean = false;

  @Input() actionButton: boolean = false;
  @Input() actionText: string = '';

  @Output() buttonClick: EventEmitter<unknown> = new EventEmitter();
  @Output() actionClick: EventEmitter<unknown> = new EventEmitter();

  constructor() {}
}
