import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from '@environment/environment';
import {EnvironmentTypeEnum} from '@environment/environment-type.enum';
import {defineCustomElements as jeepSqlite} from 'jeep-sqlite/loader';

import {defineCustomElements as pwaElementIonic} from '@ionic/pwa-elements/loader';

if (environment.environment === EnvironmentTypeEnum.PROD || environment.environment === EnvironmentTypeEnum.PREPROD) {
  enableProdMode();
}

jeepSqlite(window);
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

// Call the element loader after the platform has been bootstrapped
pwaElementIonic(window);
