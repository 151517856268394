import {ChangeDetectionStrategy, Component, ComponentRef, Input, OnInit, ViewContainerRef} from '@angular/core';
import {Labo} from '@utils/dto/labo/labo';
import {Nurse} from '@utils/dto/nurse/nurse';
import {NavigationService} from '@utils/service/navigation.service';
import {Coord} from '@utils/dto/user/adresse';

import * as L from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit {
  mode!: 'lab' | 'nurse';
  private _labs: Labo[] | null = [];
  private _nurses: Nurse[] | null = [];
  @Input() set labs(labs: Labo[] | null) {
    this._labs = labs ? labs : [];
    this.initMarker();
  }

  get labs(): Labo[] | null {
    return this._labs;
  }

  @Input() set nurses(nurses: Nurse[] | null) {
    this._nurses = nurses ? nurses : [];
    this.initMarker();
  }

  get nurses(): Nurse[] | null {
    return this._nurses;
  }

  private _coords: Coord[] | null = [];
  @Input() set coords(coords: Coord[] | null) {
    this._coords = coords ? coords : [];
    this.initMarker();
  }

  get coords(): Coord[] | null {
    return this._coords;
  }

  private map?: L.Map;
  private markersGroup: L.FeatureGroup = new L.FeatureGroup();
  private searchMarker?: L.Marker;

  private _iconCustom: L.Icon = L.icon({
    iconUrl: 'assets/svg/pin-K.svg',
    iconSize: [34, 40],
    iconAnchor: [18, 43],
    popupAnchor: [-2, -40],
  });

  private _iconMarker: L.Icon = L.icon({
    iconUrl: 'assets/svg/pin-red.svg',
    iconSize: [34, 40],
    iconAnchor: [18, 43],
    popupAnchor: [-2, -40],
  });

  private _iconPosition: L.Icon = L.icon({
    iconUrl: 'assets/svg/position-marker.svg',
    iconSize: [25, 25],
    iconAnchor: [13.5, 16.5],
  });

  constructor(private viewContainerRef: ViewContainerRef, private _nav: NavigationService) {
    if (_nav.getUrl().includes('annuaire')) {
      this.mode = 'nurse';
    } else {
      this.mode = 'lab';
    }
  }

  ngOnInit(): void {
    this.initMap();
  }

  initMap(): void {
    this.map = L.map('map', {center: [46, 2], zoom: 5, attributionControl: false, zoomControl: false});
    L.control
      .zoom({
        position: 'bottomright',
      })
      .addTo(this.map);

    const tiles: L.TileLayer = L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 18,
      minZoom: 3,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    });

    tiles.addTo(this.map);

    this.initMarker();
  }

  initMarker(): void {
    if (this.map) {
      this._clearMarker();
      this.markersGroup = new L.FeatureGroup();

      let element = this.mode === 'lab' ? this._labs : this._nurses;
      if (element) {
        element.forEach(el => {
          const markerOptions: L.MarkerOptions = {
            title: el.name,
            icon: this._iconCustom,
          };
          if (el.position) {
            const marker: L.Marker = new L.Marker(el.position.coordinates.reverse() as [number, number], markerOptions);

            const component: ComponentRef<MarkerPopup> = this.viewContainerRef.createComponent(MarkerPopup);
            component.instance.el = el;
            component.changeDetectorRef.detectChanges();

            marker
              .bindPopup(component.location.nativeElement, {closeButton: false})
              .addTo(this.markersGroup)
              .addEventListener('popupopen', (event: L.PopupEvent) => {
                if (event.target.getLatLng() && this.map) {
                  this.map.flyTo(event.target.getLatLng(), this.map.getZoom(), {duration: 0.5});
                }
              });
          }
        });
      }

      if (this.coords) {
        this.coords.forEach((coord: Coord) => {
          const markerOptions: L.MarkerOptions = {
            icon: this._iconMarker,
          };
          const marker: L.Marker = new L.Marker([coord.latitude, coord.longitude], markerOptions);

          marker.addTo(this.markersGroup);
        });
      }

      this.markersGroup.addTo(this.map);

      if (this.markersGroup.getBounds().isValid()) {
        this.map.fitBounds(this.markersGroup.getBounds());
      }
    }
  }

  updateCoord(coord: [number, number]): void {
    if (this.map) {
      if (this.searchMarker) {
        this.searchMarker.removeFrom(this.map);
      }
      this.searchMarker = new L.Marker(coord, {icon: this._iconPosition}).addTo(this.map);
    }

    this.map?.flyTo(coord, 15, {duration: 1});
  }

  resetPosition(): void {
    if (this.map) {
      if (this.searchMarker) {
        this.searchMarker.removeFrom(this.map);
      }

      if (this.markersGroup.getBounds().isValid()) {
        this.map.fitBounds(this.markersGroup.getBounds());
      }
    }
  }

  private _clearMarker(): void {
    if (this.map && this.markersGroup) {
      // clear listener before removing marker
      this.markersGroup.getLayers().forEach(layer => layer.clearAllEventListeners());
      this.markersGroup.removeFrom(this.map);
    }
  }

  /**
   * Method to fix space between tile on opera and firefox
   * Cause problems on Chrome, need some modification
   */
  // private _fixTileSize(): void {
  //   const originalInitTile: any = (L.GridLayer.prototype as any)?._initTile;
  //   L.GridLayer.include({
  //     _initTile: function (tile: any) {
  //       originalInitTile.call(this, tile);

  //       const tileSize: any = this.getTileSize();

  //       tile.style.width = tileSize.x + 1 + 'px';
  //       tile.style.height = tileSize.y + 1 + 'px';
  //     },
  //   });
  // }
}

@Component({
  selector: 'app-marker-popup',
  templateUrl: './marker-popup.html',
  styles: ['.next-button {position: absolute; bottom: 0; right: -20px}'],
})
export class MarkerPopup {
  el?: Labo | Nurse;
}
