<div class="full-size">
  <button mat-flat-button class="w-100 p-0" (click)="buttonClick.emit()">
    <div class="item d-flex align-items-center" [class.last]="last">
      <div *ngIf="nurse.profilePicture; else noProfilePicture" class="icon flex-shrink-1">
        <img [src]="nurse.profilePicture" alt="profilePicture">
      </div>
      <ng-template #noProfilePicture>
        <div class="icon flex-shrink-1">
          <img src="assets/svg/laboratory.svg">
        </div>
      </ng-template>
      <div class="px-3 text flex-grow-1 d-flex flex-column">
        <div class="d-flex justify-content-between">
          <span>{{nurse.name}}</span>
          <span class="distance" *ngIf="nurse.distance">{{nurse.distance | distance}}</span>
        </div>
        <span class="adresse"
              [title]="nurse.address1 + ',' + nurse.postalCode + ' ' + nurse.city">
          {{nurse.address1}}, {{nurse.postalCode}} {{nurse.city}}
        </span>
      </div>
      <div class="icon">
        <mat-icon [inline]="true">arrow_forward</mat-icon>
      </div>
    </div>
  </button>
</div>

