import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UserService} from '@service/user.service';
import {take} from 'rxjs';

@Component({
  selector: 'app-account-update-notifs',
  templateUrl: './account-update-notifs.component.html',
  styleUrls: ['./account-update-notifs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountUpdateNotifsComponent implements OnInit {
  active!: boolean | undefined;
  currentUserId!: string | undefined;
  constructor(private _userService: UserService, private _cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._userService
      .get()
      .pipe(take(1))
      .subscribe(user => {
        this.currentUserId = user.id;
        if (user?.notificationsEnabled !== undefined) {
          this.active = user.notificationsEnabled;
          this._cd.detectChanges();
        }
      });
  }

  activeChange(): void {
    if (this.active !== undefined && this.currentUserId) {
      this.active = !this.active;
      this._userService.updateNotification(this.active).subscribe();
    }
  }
}
