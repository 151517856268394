<div class="full-size">
  <button mat-flat-button class="w-100 p-0" (click)="buttonClick.emit()">
    <div class="item d-flex align-items-center" [class.last]="last">
      <div class="icon flex-shrink-1">
        <img src="assets/svg/laboratory.svg">
      </div>
      <div class="px-3 text flex-grow-1 d-flex flex-column">
        <div class="d-flex justify-content-between">
          <span>{{lab.name}}</span>
          <span class="distance" *ngIf="lab.distance">{{lab.distance | distance}}</span>
        </div>
        <span class="adresse"
              [title]="lab.address1 + ',' + lab.postalCode + ' ' + lab.city">
          {{lab.address1}}, {{lab.postalCode}} {{lab.city}}
        </span>
      </div>
      <div class="icon">
        <mat-icon [inline]="true">arrow_forward</mat-icon>
      </div>
    </div>
  </button>
</div>
