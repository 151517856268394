<div class="w-100 item d-flex flex-column" *ngIf="templateRef">
  <div class="position-relative">
    <div #slider class="slider">
      <ng-container [ngTemplateOutlet]="templateRef"> </ng-container>
    </div>

    <div #action class="action" *ngIf="actionTemplate">
      <ng-container [ngTemplateOutlet]="actionTemplate"></ng-container>
    </div>
  </div>
</div>
