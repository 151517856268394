import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environment/environment';
import {Adresse, AdresseApi, SearchType} from '@utils/dto/user/adresse';
import {Commune} from '@utils/dto/utils/commune';
import {NO_LOADER} from '@utils/interceptor/error.interceptor';
import {EMPTY, Observable, of} from 'rxjs';
import {take, map, switchMap, catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GeoApiService {
  private _baseUrl: string = 'https://geo.api.gouv.fr/';
  private _externalEndpoint: string = environment.api + '/external';

  constructor(private _http: HttpClient) {}

  /**
   * Autocomplete Commune by Postal code
   * @param cp
   * @returns only for a valid Postal code
   */
  autocompleteCommunesByCp(cp: string): Observable<Commune[]> {
    const params: HttpParams = new HttpParams().set('codePostal', cp).set('fields', 'codesPostaux,nom');
    return this._http.get<Commune[]>(this._baseUrl + 'communes', {params, context: NO_LOADER}).pipe(
      map(communes => {
        // keep only the postal code given in parameters
        communes.forEach(c => (c.codesPostaux = [cp]));
        return communes;
      })
    );
  }

  /**
   * @param input
   * @returns
   */
  /**
   * Search city by Name
   * If City as multiple Postal code then it will return a entry for each
   * @param input city name
   * @param limit - default 5 - number of unique city returned
   * @param boost - default true - order city by population
   * @returns
   */
  autocompleteCommunesBySearch(input: string, limit: number = 5, boost: boolean = true): Observable<Commune[]> {
    let params: HttpParams = new HttpParams().set('nom', input).set('fields', 'codesPostaux,nom').set('limit', limit);

    if (boost) {
      params = params.set('boost', 'population');
    }

    return this._http.get<Commune[]>(this._baseUrl + 'communes', {params, context: NO_LOADER}).pipe(
      map((communes: Commune[]) => {
        const newCommunes: Commune[] = [];
        communes.map(commune => {
          commune.codesPostaux?.forEach(cp => {
            newCommunes.push({nom: commune.nom, codesPostaux: [cp]});
          });
        });
        return newCommunes;
      })
    );
  }

  getCoordOfAddress(address: Adresse): Observable<AdresseApi> {
    const adr: string[] = [address.adr1, address.cp, address.ville];
    return this.search(adr.join(','), SearchType.HOUSENUMBER).pipe(
      take(1),
      switchMap((res: AdresseApi[]) => {
        return res.length ? of(res[0]) : EMPTY;
      })
    );
  }

  search(input: string, type: SearchType, limit: number = 5, autocomplete: number = 1): Observable<AdresseApi[]> {
    if (!input) {
      return of([]);
    }
    const params: HttpParams = new HttpParams().set('q', input).set('type', type).set('limit', limit).set('autocomplete', autocomplete);
    return this._http.get<AdresseApi[]>(this._externalEndpoint + '/search', {params, context: NO_LOADER}).pipe(
      catchError(() => {
        return EMPTY;
      })
    );
  }

  reverse(lat: number, lon: number): Observable<AdresseApi[]> {
    const params: HttpParams = new HttpParams().set('lat', lat).set('lon', lon);
    return this._http.get<AdresseApi[]>(this._externalEndpoint + '/reverse', {params, context: NO_LOADER});
  }
}
