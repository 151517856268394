import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environment/environment';
import {TokenResponse} from '@security/token-response';
import {StorageKey} from '@utils/enums/storage-key.enum';
import {NO_SNACKBAR_CONTEXT} from '@utils/interceptor/error.interceptor';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakTokenParsed} from 'keycloak-js';
import {firstValueFrom} from 'rxjs';
import {NavigationService} from './navigation.service';
import {StorageService} from './storage.service';

/**
 * Snackbar service
 */
@Injectable({
  providedIn: 'root',
})
export class MyKeycloakService {
  /**
   * Constructor
   */
  constructor(
    private http: HttpClient,
    private _keycloak: KeycloakService,
    private _nav: NavigationService,
    private storage: StorageService
  ) {}

  /**
   * load token if exist in storage
   */
  async loadToken(): Promise<void> {
    const res: {access: string; refresh: string} = (await this.storage.get(StorageKey.TOKEN)) as {access: string; refresh: string};
    if (res) {
      await this.setToken(res.access, res.refresh);
    }
  }

  /**
   * save token in storage and load keycloak user profile
   */
  async saveToken(): Promise<void> {
    this.storage.set(StorageKey.TOKEN, {
      access: this._keycloak.getKeycloakInstance().token,
      refresh: this._keycloak.getKeycloakInstance().refreshToken,
    });
    // wait to load userProfile
    try {
      await this._keycloak.getKeycloakInstance().loadUserProfile();
    } catch {
      console.error('failed to load user profile');
      this.logout('/');
    }
  }

  async login(username: string, password: string, redirectUri: string): Promise<void> {
    const body: HttpParams = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('client_id', environment.keycloak.client)
      .set('grant_type', 'password');

    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    const token: TokenResponse = await firstValueFrom(
      this.http.post<TokenResponse>(environment.keycloak.url + `realms/${environment.keycloak.realm}/protocol/openid-connect/token`, body, {
        headers,
        context: NO_SNACKBAR_CONTEXT,
        withCredentials: true,
      })
    ).catch(e => {
      throw e;
    });
    await this.setToken(token.access_token, token.refresh_token);
    await this.saveToken();
    this._nav.navigateByUrl(redirectUri);
  }

  async logout(redirectUri: string): Promise<void> {
    const refresh_token: string | undefined = this._keycloak.getKeycloakInstance().refreshToken;
    if (refresh_token) {
      const body: HttpParams = new HttpParams().set('refresh_token', refresh_token).set('client_id', environment.keycloak.client);

      const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

      await firstValueFrom(
        this.http.post(environment.keycloak.url + `realms/${environment.keycloak.realm}/protocol/openid-connect/logout`, body, {headers})
      );
    }
    this.storage.clear(StorageKey.TOKEN);
    this._keycloak.clearToken();
    this._nav.navigateByUrl(redirectUri);
  }

  private decodeToken(str: string): KeycloakTokenParsed {
    str = str.split('.')[1];

    str = str.replace(/-/g, '+');
    str = str.replace(/_/g, '/');
    switch (str.length % 4) {
      case 0:
        break;
      case 2:
        str += '==';
        break;
      case 3:
        str += '=';
        break;
      default:
        throw 'Invalid token';
    }

    str = decodeURIComponent(escape(atob(str)));

    return JSON.parse(str);
  }

  private async setToken(token: string, refreshToken: string): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const kc: any = this._keycloak.getKeycloakInstance();
    if (refreshToken) {
      kc.refreshToken = refreshToken;
      kc.refreshTokenParsed = this.decodeToken(refreshToken);
    } else {
      delete kc.refreshToken;
      delete kc.refreshTokenParsed;
    }

    if (token) {
      kc.token = token;
      kc.tokenParsed = this.decodeToken(token);
      kc.sessionId = kc.tokenParsed.session_state;
      kc.authenticated = true;
      kc.subject = kc.tokenParsed.sub;
      kc.realmAccess = kc.tokenParsed.realm_access;
      kc.resourceAccess = kc.tokenParsed.resource_access;

      if (!kc.timeSkew) {
        if (kc.onTokenExpired) {
          const expiresIn: number = (kc.tokenParsed['exp'] - new Date().getTime() / 1000 + kc.timeSkew) * 1000;
          if (expiresIn <= 0) {
            kc.onTokenExpired();
          } else {
            kc.tokenTimeoutHandle = setTimeout(kc.onTokenExpired, expiresIn);
          }
        }
      }
      // await kc.loadUserProfile();
    } else {
      delete kc.token;
      delete kc.tokenParsed;
      delete kc.subject;
      delete kc.realmAccess;
      delete kc.resourceAccess;

      this.storage.clear(StorageKey.TOKEN);

      kc.authenticated = false;
    }
  }
}
