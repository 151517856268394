import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {ProfileEnum} from '@utils/enums/profile.enum';
import {NavigationService} from '@utils/service/navigation.service';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard extends KeycloakAuthGuard implements CanActivate, CanActivateChild {
  constructor(protected readonly _router: Router, protected readonly keycloak: KeycloakService, private _nav: NavigationService) {
    super(_router, keycloak);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    return this._canActivateRoute(childRoute, state);
  }

  public isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this._canActivateRoute(route, state);
  }

  private async _canActivateRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // Get the roles required from the route.
    const requiredRoles: string[] = route.data['roles'];

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Si l’utilisateur à un role contenu dans les roles requis
    if (this.roles && requiredRoles.some(value => this.roles.includes(value))) {
      // On valide
      return true;
    }

    const roles: string[] = this.roles.filter(role => ([ProfileEnum.PATIENT, ProfileEnum.PRELEVEUR] as string[]).includes(role));

    // Sinon on redirige
    // todo à améliorer quand on connaîtra les rôles et pour avoir plus que 1 rôle par URL
    switch (roles?.[0]) {
      case 'PRELEVEUR':
        this._nav.navigate(['/preleveur']);
        break;
      case 'PATIENT':
        this._nav.navigate(['/patient']);
        break;
      default:
        this.keycloak.logout();
        break;
    }
    return false;
  }
}
