import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SampleService} from '@service/sample.service';
import {Routing} from '@utils/dto/routing';
import {Sample, SampleWithLabo} from '@utils/dto/sample/sample';
import {SampleStatusEnum} from '@utils/enums/sample-status.enum';
import {StorageKey} from '@utils/enums/storage-key.enum';
import {NavigationService} from '@utils/service/navigation.service';
import {StorageService} from '@utils/service/storage.service';
import {firstValueFrom} from 'rxjs';
import {Message} from '../../dto/message';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input() notification!: Message;
  @Input() last: boolean = false;
  @Input() isNew: boolean = false;

  SampleStatusEnum: typeof SampleStatusEnum = SampleStatusEnum;

  constructor(private _sampleService: SampleService, private _nav: NavigationService, private _storage: StorageService) {}

  async reschedule(): Promise<void> {
    if (this.notification?.data?.oldSample) {
      try {
        const oldSample: SampleWithLabo = await firstValueFrom(this._sampleService.getWithLabo(this.notification.data.oldSample));

        const sample: Sample = {
          userId: oldSample.userId,
          dateSample: undefined,
          adr1: oldSample.adr1,
          adr2: oldSample.adr2,
          cp: oldSample.cp,
          ville: oldSample.ville,
          pays: oldSample.pays,
          commentaire: oldSample.commentaire,
          inLabo: oldSample.inLabo,
          checks: oldSample.checks,
          homeRx: oldSample.homeRx,
          hasRx: oldSample.hasRx,
          docs: oldSample.docs,
        };

        const data: {sample?: Sample; domicile: boolean; reschedule: boolean} = {
          sample,
          domicile: !sample.inLabo,
          reschedule: true,
        };

        // reschedule, reset potentiel selected checks
        this._storage.clear([StorageKey.SAMPLE_CHECKS, StorageKey.SAMPLE_ADD_CHECKS]);

        const history: Routing[] = [{url: '/patient/sample/new/choice'}, {url: '/patient/sample/new/domicile'}];
        this._nav.rewriteNav(history);
        this._nav.navigate(['/patient/sample/new/domicile/docs'], {state: {data: data}});
      } catch (e) {
        console.error(e);
      }
    }
  }
}
