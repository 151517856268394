import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Adresse} from '@utils/dto/user/adresse';

@Component({
  selector: 'app-adr-item',
  templateUrl: './adr-item.component.html',
  styleUrls: ['./adr-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdrItemComponent implements OnInit {
  @Input() adr!: Adresse;
  @Input() home?: boolean;
  @Input() index?: number;
  @Input() last?: boolean;
  @Input() fullSize: boolean = true;

  @Output() buttonClick: EventEmitter<unknown> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
