import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appShowPicker]',
})
export class ShowPickerDirective {
  constructor(private _elemRef: ElementRef) {}

  @HostListener('focus')
  onFocus(): void {
    if (this._elemRef.nativeElement.showPicker) {
      // showPicker() is supported.
      this._elemRef.nativeElement.showPicker();
    }
  }
}
