<div class="d-flex h-100 align-items-start flex-column" *transloco="let t; read: 'patient.init_doc'">
  <app-simple-header titre="patient.init_doc.title" [back]="true" class="w-100"></app-simple-header>

  <div class="mb-auto w-100">
    <app-section>
      <p>{{t('text')}}</p>

      <app-file-input text="patient.init_doc.id_card" [hybrid]="true" [files]="files.get(DocumentTypeEnum.ID_CARD)"
                      (fileChange)="fileChange(DocumentTypeEnum.ID_CARD, $event)"></app-file-input>
      <app-file-input
                      text="patient.init_doc.vital_card" [hybrid]="true"
                      [files]="files.get(DocumentTypeEnum.HEALTH_CARD)"
                      (fileChange)="fileChange(DocumentTypeEnum.HEALTH_CARD, $event)">
      </app-file-input>
      <app-file-input
                      text="patient.init_doc.vital_cert" [hybrid]="true"
                      [files]="files.get(DocumentTypeEnum.HEALTH_ATTESTATION)"
                      (fileChange)="fileChange(DocumentTypeEnum.HEALTH_ATTESTATION, $event)">
      </app-file-input>
      <app-file-input
                      text="patient.init_doc.insurance_card" [hybrid]="true"
                      [files]="files.get(DocumentTypeEnum.INSURANCE_CARD)"
                      (fileChange)="fileChange(DocumentTypeEnum.INSURANCE_CARD, $event)">
      </app-file-input>
    </app-section>
  </div>

  <div class="button-footer mb-2 pb-2">
    <app-button-icon (buttonClick)="validate()" text="app.save" [disabled]="loading"></app-button-icon>
  </div>
</div>
