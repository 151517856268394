import {DocumentDto} from './document';

export class FileDto {
  file?: File;
  doc?: DocumentDto;
  img?: string;
}

export const toBase64 = (file: Blob | File): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const urltoFile = (url: string, filename: string, mimeType: string): Promise<File> => {
  return fetch(url)
    .then((res: Response) => {
      return res.arrayBuffer();
    })
    .then((buf: ArrayBuffer) => {
      return new File([buf], filename, {type: mimeType});
    });
};
