<button *ngIf="type === 'button'" mat-flat-button [color]="color" class="large-button mb-1 position-relative"
        [ngClass]="position" [class.no-icon]="noIcon" [class.small]="small" [class.w-100]="w100" [disabled]="disabled"
        (click)="buttonClick.emit()">
  <span class="">{{text | transloco }}</span>
  <mat-icon *ngIf="!noIcon" class="position-absolute" [inline]="true">{{icon}}</mat-icon>
</button>

<button *ngIf="type === 'text'" mat-button [color]="color" class="large-button text mb-1 position-relative"
        [ngClass]="position" [class.no-icon]="noIcon" [class.small]="small" [class.w-100]="w100" [disabled]="disabled"
        (click)="buttonClick.emit()">
  <span class="">{{text | transloco }}</span>
  <mat-icon *ngIf="!noIcon" class="position-absolute" [inline]="true">{{icon}}
  </mat-icon>
</button>
