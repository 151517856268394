import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MyKeycloakService} from '@utils/service/my-keycloak.service';
import {StorageService} from '@utils/service/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  private redirectUri: string = '/';

  /**
   * password visibility
   */
  visibility: boolean = false;

  error?: string;

  username: FormControl = new FormControl('');
  password: FormControl = new FormControl('');

  constructor(
    private _keycloak: MyKeycloakService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _cd: ChangeDetectorRef,
    private _storage: StorageService
  ) {
    // todo à remettre quand on aura autre chose qu’un lorem ipsum
    // this._storage.get(StorageKey.DISCLAMER).then(res => {
    //   if (!res) {
    //     _router.navigateByUrl('/disclaimer');
    //   }
    // });
    const redirect: string = _route.snapshot.queryParams['redirectUri']?.replace(window.location.origin, '');

    // to avoid cycle on login page
    if (redirect && redirect !== '/login') {
      this.redirectUri = redirect;
    }

    // clear register storage
    // FIXME: cause des problèmes pour la reprise de l'inscription
    // const toClear: string[] = [StorageKey.STEP_1, StorageKey.STEP_2, StorageKey.STEP_3, StorageKey.STEP_4, StorageKey.REGISTER_STEP];
    // _storage.clear(toClear);
  }

  login(): void {
    this._keycloak.login(this.username.value, this.password.value, this.redirectUri).catch((e: HttpErrorResponse) => {
      this.error = e?.error?.error;
      this._cd.detectChanges();
    });
  }
}
