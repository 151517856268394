import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'distance',
  pure: true,
})
export class DistancePipe implements PipeTransform {
  constructor() {}

  transform(value: any, args?: any): any {
    if (value) {
      if (value < 1000) {
        return value + ' m';
      } else {
        return Math.round((value / 1000 + Number.EPSILON) * 100) / 100 + ' km';
      }
    }
    return value;
  }
}
