import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'hour',
})
export class HourPipe implements PipeTransform {
  transform(value?: string, simple: boolean = false): string {
    if (value) {
      return value.replace(':', 'h').substring(0, simple ? 3 : 5);
    } else {
      return '';
    }
  }
}
