<div class="d-flex h-100 align-items-start flex-column">
  <app-simple-header [titre]=" mode === 'patient' ? 'account.adresses.title' : 'account.adresses.title_sing'" [back]="true" class="w-100"></app-simple-header>

  <div class="d-flex flex-column flex-grow-1 w-100" *transloco="let t; read: 'account.adresses'">
    <app-adresse-form [titre]=" mode === 'patient' ? 'account.adresses.domicile' : ''" [formArray]="formArray"></app-adresse-form>
    <div *ngIf="mode === 'patient'">
      <app-adresse-form  *ngFor="let form of nbForm | slice:1 ; let even = even; let index = index " [alternate]="even"
                         [translate]="false"
                         [formArray]="formArray"
                         [titre]="(t('prelevement')) + ' ' + (index+2)"
                         [canDelete]="true"
                         (onDelete)="removeAdr(index)">
      </app-adresse-form>
    </div>

  </div>

  <div class="button-footer mb-2 pb-2">
    <app-button-icon *ngIf="mode==='patient'" type="text" (buttonClick)="addAdr()" text="form.adr.new" icon="add" position="left">
    </app-button-icon>
    <app-button-icon (buttonClick)="validate()" text="app.save"></app-button-icon>
  </div>
</div>
