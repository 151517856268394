import {Component} from '@angular/core';
import {RouterModule} from '@angular/router';
import {UserInfo} from '@security/user-info';
import {NavigationService} from '@utils/service/navigation.service';
import {KeycloakService} from 'keycloak-angular';

@Component({
  imports: [RouterModule],
  template: `<span>Redirecting...</span>`,
  standalone: true,
})
export class RedirectComponent extends UserInfo {
  /**
   * Permet de définir vers ou rediriger suite à un accès via l’URL de base (login par exemple)
   * Un guard devra vérifier si l’utilisateur à ensuite vraiment accès à la page ou non
   */
  constructor(protected override keycloak: KeycloakService, private _nav: NavigationService) {
    super(keycloak);

    // todo à améliorer quand on connaîtra les rôles
    switch (this.roles?.[0]) {
      case 'PRELEVEUR':
        this._nav.navigate(['/preleveur']);
        break;
      case 'PATIENT':
      default:
        this._nav.navigate(['/patient']);
        break;
    }
  }
}
