import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Capacitor} from '@capacitor/core';
import {environment} from '@environment/environment';
import {NO_SNACKBAR_CONTEXT} from '@utils/interceptor/error.interceptor';
import {MyKeycloakService} from '@utils/service/my-keycloak.service';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakInitOptions, KeycloakLoginOptions} from 'keycloak-js';
import {merge} from 'lodash';
import {lastValueFrom} from 'rxjs';

export function initializeKeycloak(
  keycloak: KeycloakService,
  http: HttpClient,
  router: Router,
  myKc: MyKeycloakService
): () => Promise<boolean> {
  async function customLogin(options?: KeycloakLoginOptions): Promise<void> {
    await myKc.loadToken();
    await keycloak
      .updateToken(-1)
      .then(res => {
        if (!res) {
          const params: string = options?.redirectUri ? '?redirectUri=' + options?.redirectUri : '';
          router.navigateByUrl('/login' + params);
        } else {
          myKc.saveToken();
        }
        console.log('token refreshed : ' + res);
      })
      .catch(() => {
        const params: string = options?.redirectUri ? '?redirectUri=' + options?.redirectUri : '';
        router.navigateByUrl('/login' + params);
      });
  }

  async function customLogout(redirectUri?: string): Promise<void> {
    const params: string = redirectUri ? '?redirectUri=' + redirectUri : '';
    router.navigateByUrl('/logout' + params);
  }

  return async (): Promise<boolean> => {
    // Get environment configuration from server and override local properties.
    // Keep bootstraping the app if the configuration cannot be fetched from server and
    // use bundled environment file.
    try {
      const config: Object = await lastValueFrom(http.get('/config.json', {context: NO_SNACKBAR_CONTEXT}));
      merge(environment, config);
    } catch (e) {
      console.error(e);

      // ignored
    }

    const initOptions: KeycloakInitOptions = {
      checkLoginIframe: false,
      adapter: 'default',
    };

    if (Capacitor.isNativePlatform()) {
      initOptions.adapter = 'cordova';
    }

    // (keycloak as any).oldLogin = keycloak.login;
    keycloak.login = customLogin;
    // (keycloak as any).oldLogout = keycloak.logout;
    keycloak.logout = customLogout;

    return keycloak.init({
      config: {
        url: environment.keycloak.url,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.client,
      },
      initOptions,
      enableBearerInterceptor: true,
      loadUserProfileAtStartUp: true,
      bearerExcludedUrls: ['/assets'],
    });
  };
}
