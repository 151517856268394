import {KeycloakService} from 'keycloak-angular';
import {KeycloakProfile} from 'keycloak-js';

export class UserInfo {
  roles?: string[];
  user?: KeycloakProfile;
  token?: string;

  constructor(protected keycloak: KeycloakService) {
    if (keycloak?.getKeycloakInstance()) {
      this.user = keycloak.getKeycloakInstance().profile;
      this.roles = keycloak.getUserRoles(true);
      this.token = keycloak.getKeycloakInstance().token;
    }
  }
}
