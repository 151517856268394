<div class="h-100 d-flex flex-column" *transloco="let t; read: 'search.' + mode">
  <app-simple-header class="w-100" [back]="true"
                     [actionButton]="mode === 'register'"
                     actionText="search.register.show_map"
                     (actionClick)="redirectToMap()">
  </app-simple-header>

  <div class="grey">
    <mat-form-field appearance="fill" class="mt-2">
      <mat-icon matPrefix class="grey">search</mat-icon>
      <mat-label>{{t('search_placeholder')}}</mat-label>
      <input
             type="text"
             matInput
             #input
             (input)="input$.next(position.value)"
             [matAutocomplete]="auto"
             [formControl]="position"
             autocomplete="off">
      <button *ngIf="position.value" class="grey" matSuffix mat-icon-button aria-label="Clear"
              (click)="resetField()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngIf="!position.value" value="Ma position" (onSelectionChange)="currentPosition()">
          <mat-icon class="grey">location_pin</mat-icon>
          {{'app.my_loc' | transloco}}
        </mat-option>
        <mat-option *ngIf="domicile && !position.value" value="Domicile" (onSelectionChange)="selectHome()">
          <mat-icon class="grey">home</mat-icon>
          {{t('home')}}
        </mat-option>
        <mat-option *ngFor="let suggest of autosuggest$ | async" (onSelectionChange)="select(suggest)"
                    [value]="getOptionText(suggest)">
          {{getOptionText(suggest)}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="grey" *ngIf="position.value">
    <span>{{t('select_label')}}</span>
    <div class="mt-2">
      <div *ngIf="mode !== 'nurse'; else nurse">
        <app-lab-item *ngFor="let lab of foundLabs; let last = last" [lab]="lab" [last]="last"
                      (buttonClick)="getDetails(lab.id)"></app-lab-item>
      </div>
      <ng-template #nurse>
        <app-nurse-item *ngFor="let nurse of foundNurses; let last=last" [nurse]="nurse" [last]="last"
        (buttonClick)="getDetails(nurse?.id)"></app-nurse-item>
      </ng-template>
      <div *ngIf="!foundLabs?.length && !loading && mode !=='nurse'">
        <span class="fst-italic small">Aucun laboratoire à proximité</span>
      </div>
      <div *ngIf="!foundNurses?.length && !loading && mode === 'nurse'">
        <span class="fst-italic small">Aucun infirmier à proximité</span>
      </div>
    </div>
  </div>

</div>
