export enum SampleStatusEnum {
  PENDING = 'PENDING',
  AFFECTED = 'AFFECTED',
  CONFIRM = 'CONFIRM',
  CANCEL = 'CANCEL',
  UNDERWAY = 'UNDERWAY',
  INPROGRESS = 'INPROGRESS',
  TOLABO = 'TOLABO',
  TOVALIDATE = 'TOVALIDATE',
  DONE = 'DONE',
  REFUSED = 'REFUSED',
}
