import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonIconComponent {
  @Input() text!: string;
  @Input() position: 'left' | 'right' = 'right';
  @Input() noIcon: boolean = false;
  @Input() icon: string = 'arrow_forward';
  @Input() type: 'button' | 'text' = 'button';
  @Input() small: boolean = false;
  @Input() disabled: boolean = false;
  @Input() color: 'accent' | 'primary' | 'warn' | '' = 'primary';
  @Input() w100: boolean = true;

  @Output() buttonClick: EventEmitter<unknown> = new EventEmitter();

  constructor() {}
}
