<app-section [titre]="titre" [alternate]="alternate" [translate]="translate" *transloco="let t; read: 'form.adr'">
  <form [formGroup]="formGroup">
    <mat-form-field appearance="fill">
      <mat-label>{{t('rue')}}</mat-label>
      <input type="text" matInput formControlName="rue" autocomplete="address-line1">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{t('compl')}}</mat-label>
      <input type="text" matInput formControlName="compl" autocomplete="address-line2">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{t('cp')}}</mat-label>
      <input type="text"
             matInput
             autocomplete="postal-code"
             maxlength="5"
             (input)="inputCpSearch($event)"
             formControlName="cp"
             [matAutocomplete]="autoCp">
      <mat-autocomplete #autoCp="matAutocomplete">
        <mat-option *ngFor="let suggest of autosuggestCp$ | async" (onSelectionChange)="select(suggest)"
                    [value]="suggest.codesPostaux[0]">
          {{suggest.nom}} - {{suggest.codesPostaux.length ? suggest.codesPostaux[0] : ''}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{t('comm')}}</mat-label>
      <input type="text" matInput formControlName="comm">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{t('ville')}}</mat-label>
      <input type="text"
             matInput
             formControlName="ville"
             autocomplete="address-level2"
             (input)="inputVilleSearch($event)"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let suggest of autosuggestVille$ | async" (onSelectionChange)="select(suggest)"
                    [value]="suggest.nom">
          {{suggest.nom}} - {{suggest.codesPostaux.length ? suggest.codesPostaux[0] : ''}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{t('pays')}}</mat-label>
      <input type="text" matInput formControlName="pays" autocomplete="country-name">
    </mat-form-field>
  </form>

  <button *ngIf="canDelete" (click)="onDelete.emit()" mat-button color="warn"
          class="w-100 red">{{t('delete')}}</button>
</app-section>
