/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/typedef */
import {
  ChangeDetectionStrategy,
  Component,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  ContentChild,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-slide-item',
  templateUrl: './slide-item.component.html',
  styleUrls: ['./slide-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SlideItemComponent implements AfterViewInit {
  @Input() swipe: 'left' | 'right' = 'right';
  @Input() disabled: boolean = false;

  @ContentChild(TemplateRef) templateRef?: TemplateRef<unknown>;
  @ContentChild('actionTemplate') actionTemplate?: TemplateRef<unknown>;

  @ViewChild('slider') sliderEl!: ElementRef;

  private xDown: number | null = null;
  private yDown: number | null = null;
  private xUp: number | null = null;
  private yUp: number | null = null;

  @Output() slideAction: EventEmitter<never> = new EventEmitter<never>();

  constructor() {}

  ngAfterViewInit(): void {
    if (this.sliderEl?.nativeElement) {
      const el: HTMLElement = this.sliderEl.nativeElement;
      el.addEventListener('touchstart', this.handleTouchStart.bind(this), {passive: true});
      el.addEventListener('touchmove', this.handleTouchMove.bind(this), {passive: true});
      el.addEventListener('touchend', this.handleTouchEnd.bind(this), {passive: true});
      el.addEventListener('mousedown', this.handleTouchStart.bind(this), {passive: true});
      el.addEventListener('mousemove', this.handleTouchMove.bind(this), {passive: true});
      el.addEventListener('mouseup', this.handleTouchEnd.bind(this), {passive: true});
    }
  }

  getTouches(evt: any) {
    // get browser and mobile compatibility
    return evt instanceof MouseEvent
      ? [evt]
      : evt.touches || // browser API
          evt.originalEvent.touches; // jQuery;
  }

  handleTouchStart(evt: MouseEvent | TouchEvent) {
    if (!this.disabled) {
      const firstTouch = this.getTouches(evt)[0];
      this.xDown = firstTouch.clientX;
      this.yDown = firstTouch.clientY;
      this.xUp = firstTouch.clientX;
      this.yUp = firstTouch.clientY;
      this.sliderEl.nativeElement.style.transition = '';
    }
  }

  handleTouchEnd(evt: MouseEvent | TouchEvent) {
    if (!this.xDown || !this.yDown || !this.xUp || !this.yUp) {
      return;
    }
    const touch = evt instanceof MouseEvent ? evt : evt.touches[0];

    if (touch) {
      this.xUp = touch.clientX;
      this.yUp = touch.clientY;
    }

    const xDiff = this.xDown - this.xUp;
    const yDiff = this.yDown - this.yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0 && this.swipe === 'right') {
        /* right swipe */
        this.handleAction(xDiff);
      } else if (xDiff < 0 && this.swipe === 'left') {
        /* left swipe */
        this.handleAction(xDiff);
      }
    }
    /* reset values */
    this.xDown = null;
    this.yDown = null;
    this.xUp = null;
    this.yUp = null;

    /* element reset position */
    this.sliderEl.nativeElement.style.transition = 'all 0.25s linear';
    this.sliderEl.nativeElement.style.transform = '';
  }

  handleTouchMove(evt: MouseEvent | TouchEvent) {
    if (!this.xDown || !this.yDown) {
      return;
    }
    const touch = evt instanceof MouseEvent ? evt : evt.touches[0];

    this.xUp = touch.clientX;
    this.yUp = touch.clientY;

    const xDiff = this.xDown - this.xUp;
    const yDiff = this.yDown - this.yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0 && this.swipe === 'right') {
        /* right swipe */
        this.sliderEl.nativeElement.style.transform = `translateX(-${xDiff}px)`;
      } else if (xDiff < 0 && this.swipe === 'left') {
        /* left swipe */
        this.sliderEl.nativeElement.style.transform = `translateX(${Math.abs(xDiff)}px)`;
      }
    }
  }

  handleAction(xDiff: number): void {
    const limit: number = this.sliderEl.nativeElement.offsetWidth / 2;

    if (Math.abs(xDiff) > limit) {
      this.slideAction.emit();
    }
  }
}
