export enum StorageKey {
  DISCLAMER = 'disclamer',
  LAST_CHECK = 'lastCheck',
  NEW_ADR = 'new_adr',
  REGISTER_STEP = 'register_step',
  RX_FORM = 'form_',
  SAMPLE_CHECKS = 'checks',
  SAMPLE_ADD_CHECKS = 'add_checks',
  STEP_1 = 'step1',
  STEP_2 = 'step2',
  STEP_3 = 'step3',
  STEP_4 = 'step4',
  TOKEN = 'token',
}
