import {Injectable} from '@angular/core';
import {
  CapacitorSQLite,
  capEchoResult,
  capNCDatabasePathResult,
  capSQLiteChanges,
  capSQLiteResult,
  capSQLiteSet,
  capSQLiteValues,
  SQLiteConnection,
  SQLiteDBConnection,
} from '@capacitor-community/sqlite';
import {Capacitor} from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  sqlite?: SQLiteConnection;
  isService: boolean = false;
  platform: string = 'web';
  sqlitePlugin: any;
  native: boolean = false;

  /**
   * Plugin Initialization
   */
  initializePlugin(): Promise<boolean> {
    return new Promise(resolve => {
      this.platform = Capacitor.getPlatform();
      if (this.platform === 'ios' || this.platform === 'android') this.native = true;
      this.sqlitePlugin = CapacitorSQLite;
      this.sqlite = new SQLiteConnection(this.sqlitePlugin);
      this.isService = true;
      resolve(true);
    });
  }

  /**
   * Echo a value
   * @param value
   */
  async echo(value: string): Promise<capEchoResult> {
    if (this.sqlite) {
      try {
        const ret: capEchoResult = await this.sqlite.echo(value);
        return Promise.resolve(ret);
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error('no connection open'));
    }
  }

  async isSecretStored(): Promise<capSQLiteResult> {
    if (!this.native) {
      return Promise.reject(new Error(`Not implemented for ${this.platform} platform`));
    }
    if (this.sqlite) {
      try {
        return Promise.resolve(await this.sqlite.isSecretStored());
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  async setEncryptionSecret(passphrase: string): Promise<void> {
    if (!this.native) {
      return Promise.reject(new Error(`Not implemented for ${this.platform} platform`));
    }
    if (this.sqlite) {
      try {
        return Promise.resolve(await this.sqlite.setEncryptionSecret(passphrase));
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  async changeEncryptionSecret(passphrase: string, oldpassphrase: string): Promise<void> {
    if (!this.native) {
      return Promise.reject(new Error(`Not implemented for ${this.platform} platform`));
    }
    if (this.sqlite) {
      try {
        return Promise.resolve(await this.sqlite.changeEncryptionSecret(passphrase, oldpassphrase));
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * addUpgradeStatement
   * @param database
   * @param fromVersion
   * @param toVersion
   * @param statement
   * @param set
   */
  async addUpgradeStatement(
    database: string,
    fromVersion: number,
    toVersion: number,
    statement: string[],
    set?: capSQLiteSet[]
  ): Promise<void> {
    if (this.sqlite) {
      try {
        await this.sqlite.addUpgradeStatement(database, toVersion, statement);
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open for ${database}`));
    }
  }

  /**
   * get a non-conformed database path
   * @param folderPath
   * @param database
   * @returns Promise<capNCDatabasePathResult>
   * @since 3.3.3-1
   */
  async getNCDatabasePath(folderPath: string, database: string): Promise<capNCDatabasePathResult> {
    if (this.sqlite) {
      try {
        const res: capNCDatabasePathResult = await this.sqlite.getNCDatabasePath(folderPath, database);
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open for ${database}`));
    }
  }

  /**
   * Create a non-conformed database connection
   * @param databasePath
   * @param version
   * @returns Promise<SQLiteDBConnection>
   * @since 3.3.3-1
   */
  async createNCConnection(databasePath: string, version: number): Promise<SQLiteDBConnection> {
    if (this.sqlite) {
      try {
        const db: SQLiteDBConnection = await this.sqlite.createNCConnection(databasePath, version);
        if (db) {
          return Promise.resolve(db);
        } else {
          return Promise.reject(new Error(`no db returned is null`));
        }
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open for ${databasePath}`));
    }
  }

  /**
   * Close a non-conformed database connection
   * @param databasePath
   * @returns Promise<void>
   * @since 3.3.3-1
   */
  async closeNCConnection(databasePath: string): Promise<void> {
    if (this.sqlite) {
      try {
        await this.sqlite.closeNCConnection(databasePath);
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open for ${databasePath}`));
    }
  }

  /**
   * Check if a non-conformed databaseconnection exists
   * @param databasePath
   * @returns Promise<capSQLiteResult>
   * @since 3.3.3-1
   */
  async isNCConnection(databasePath: string): Promise<capSQLiteResult> {
    if (this.sqlite) {
      try {
        return Promise.resolve(await this.sqlite.isNCConnection(databasePath));
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Retrieve a non-conformed database connection
   * @param databasePath
   * @returns Promise<SQLiteDBConnection>
   * @since 3.3.3-1
   */
  async retrieveNCConnection(databasePath: string): Promise<SQLiteDBConnection> {
    if (this.sqlite) {
      try {
        return Promise.resolve(await this.sqlite.retrieveNCConnection(databasePath));
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open for ${databasePath}`));
    }
  }

  /**
   * Check if a non conformed database exists
   * @param databasePath
   * @returns Promise<capSQLiteResult>
   * @since 3.3.3-1
   */
  async isNCDatabase(databasePath: string): Promise<capSQLiteResult> {
    if (this.sqlite) {
      try {
        return Promise.resolve(await this.sqlite.isNCDatabase(databasePath));
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Create a connection to a database
   * @param database
   * @param encrypted
   * @param mode
   * @param version
   */
  async createConnection(database: string, encrypted: boolean, mode: string, version: number): Promise<SQLiteDBConnection> {
    if (this.sqlite) {
      try {
        /*                if(encrypted) {
                            if(this.native) {
                                const isSet = await this.sqlite.isSecretStored()
                                if(!isSet.result) {
                                    return Promise.reject(new Error(`no secret phrase registered`));
                                }
                            }
                        }
        */
        const db: SQLiteDBConnection = await this.sqlite.createConnection(database, encrypted, mode, version, false);
        if (db) {
          return Promise.resolve(db);
        } else {
          return Promise.reject(new Error(`no db returned is null`));
        }
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open for ${database}`));
    }
  }

  /**
   * Close a connection to a database
   * @param database
   */
  async closeConnection(database: string): Promise<void> {
    if (this.sqlite) {
      try {
        await this.sqlite.closeConnection(database, false);
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open for ${database}`));
    }
  }

  /**
   * Retrieve an existing connection to a database
   * @param database
   */
  async retrieveConnection(database: string): Promise<SQLiteDBConnection> {
    if (this.sqlite) {
      try {
        return Promise.resolve(await this.sqlite.retrieveConnection(database, false));
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open for ${database}`));
    }
  }

  /**
   * Retrieve all existing connections
   */
  async retrieveAllConnections(): Promise<Map<string, SQLiteDBConnection>> {
    if (this.sqlite) {
      try {
        const myConns: Map<string, SQLiteDBConnection> = await this.sqlite.retrieveAllConnections();
        /*                let keys = [...myConns.keys()];
                        keys.forEach( (value) => {
                            console.log("Connection: " + value);
                        });
        */
        return Promise.resolve(myConns);
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Close all existing connections
   */
  async closeAllConnections(): Promise<void> {
    if (this.sqlite) {
      try {
        return Promise.resolve(await this.sqlite.closeAllConnections());
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Check if connection exists
   * @param database
   */
  async isConnection(database: string): Promise<capSQLiteResult> {
    if (this.sqlite) {
      try {
        return Promise.resolve(await this.sqlite.isConnection(database, false));
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Check Connections Consistency
   * @returns
   */
  async checkConnectionsConsistency(): Promise<capSQLiteResult> {
    if (this.sqlite) {
      try {
        const res: capSQLiteResult = await this.sqlite.checkConnectionsConsistency();
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Check if database exists
   * @param database
   */
  async isDatabase(database: string): Promise<capSQLiteResult> {
    if (this.sqlite) {
      try {
        return Promise.resolve(await this.sqlite.isDatabase(database));
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Get the list of databases
   */
  async getDatabaseList(): Promise<capSQLiteValues> {
    if (this.sqlite) {
      try {
        return Promise.resolve(await this.sqlite.getDatabaseList());
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Get Migratable databases List
   */
  async getMigratableDbList(folderPath?: string): Promise<capSQLiteValues> {
    if (!this.native) {
      return Promise.reject(new Error(`Not implemented for ${this.platform} platform`));
    }
    if (this.sqlite) {
      try {
        if (!folderPath || folderPath.length === 0) {
          return Promise.reject(new Error(`You must provide a folder path`));
        }
        return Promise.resolve(await this.sqlite.getMigratableDbList(folderPath));
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Add "SQLite" suffix to old database's names
   */
  async addSQLiteSuffix(folderPath?: string, dbNameList?: string[]): Promise<void> {
    if (!this.native) {
      return Promise.reject(new Error(`Not implemented for ${this.platform} platform`));
    }
    if (this.sqlite) {
      try {
        const path: string = folderPath ? folderPath : 'default';
        const dbList: string[] = dbNameList ? dbNameList : [];
        return Promise.resolve(await this.sqlite.addSQLiteSuffix(path, dbList));
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Delete old databases
   */
  async deleteOldDatabases(folderPath?: string, dbNameList?: string[]): Promise<void> {
    if (!this.native) {
      return Promise.reject(new Error(`Not implemented for ${this.platform} platform`));
    }
    if (this.sqlite) {
      try {
        const path: string = folderPath ? folderPath : 'default';
        const dbList: string[] = dbNameList ? dbNameList : [];
        return Promise.resolve(await this.sqlite.deleteOldDatabases(path, dbList));
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  async deleteDatabase(db: SQLiteDBConnection): Promise<void> {
    try {
      const ret: capSQLiteResult = await db.isExists();
      if (ret.result) {
        const dbName: string = db.getConnectionDBName();
        console.log('deleting db ', dbName);
        await db.delete();
        return Promise.resolve();
      } else {
        return Promise.resolve();
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
   * Import from a Json Object
   * @param jsonString
   */
  async importFromJson(jsonString: string): Promise<capSQLiteChanges> {
    if (this.sqlite) {
      try {
        return Promise.resolve(await this.sqlite.importFromJson(jsonString));
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Is Json Object Valid
   * @param jsonString Check the validity of a given Json Object
   */
  async isJsonValid(jsonString: string): Promise<capSQLiteResult> {
    if (this.sqlite) {
      try {
        return Promise.resolve(await this.sqlite.isJsonValid(jsonString));
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Copy databases from public/assets/databases folder to application databases folder
   */
  async copyFromAssets(overwrite?: boolean): Promise<void> {
    const mOverwrite: boolean | undefined = overwrite !== null ? overwrite : true;
    console.log(`&&&& mOverwrite ${mOverwrite}`);
    if (this.sqlite) {
      try {
        return Promise.resolve(await this.sqlite.copyFromAssets(mOverwrite));
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Initialize the Web store
   */
  async initWebStore(): Promise<void> {
    if (this.platform !== 'web') {
      return Promise.reject(new Error(`not implemented for this platform: ${this.platform}`));
    }
    if (this.sqlite) {
      try {
        await this.sqlite.initWebStore();
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Save a database to store
   * @param database
   */
  async saveToStore(database: string): Promise<void> {
    if (this.platform !== 'web') {
      return Promise.reject(new Error(`not implemented for this platform: ${this.platform}`));
    }
    if (this.sqlite) {
      try {
        await this.sqlite.saveToStore(database);
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(new Error(err as never));
      }
    } else {
      return Promise.reject(new Error(`no connection open for ${database}`));
    }
  }
}
