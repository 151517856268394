<div class="d-flex flex-column mb-4">
  <span class="mb-2"><b>{{el?.name}}</b></span>
  <span>{{el?.address1}},</span>
  <span *ngIf="el?.address2">{{el?.address2}}</span>
  <span>{{el?.postalCode}} {{el?.city}}</span>
</div>
<div class="position-relative">
  <button mat-icon-button class="grey next-button" [navigation]="['../detail/',el?.id]">
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>
