import {AdrForm} from '../register-step';

export class Adresse {
  id?: string;
  adr1!: string;
  adr2?: string;
  cp!: string;
  ville!: string;
  pays?: string;
  commentaire?: string;
  domicile?: boolean;

  static fromFormValues(formValues: AdrForm[]): Adresse[] {
    const adrs: Adresse[] = [];
    formValues.forEach(value => {
      adrs.push(Adresse.fromFormValue(value));
    });
    return adrs;
  }

  static fromFormValue(formValue: AdrForm): Adresse {
    const adr: Adresse = new Adresse();
    adr.id = formValue.id || '';
    adr.adr1 = formValue.rue;
    adr.adr2 = formValue.compl;
    adr.cp = formValue.cp;
    adr.ville = formValue.ville;
    adr.pays = formValue.pays;
    adr.commentaire = formValue.comm;
    adr.domicile = false;
    return adr;
  }

  static toFormValue(adr: Adresse): AdrForm {
    const adrForm: AdrForm = {
      id: adr.id || '',
      rue: adr.adr1,
      compl: adr.adr2 || '',
      cp: adr.cp,
      ville: adr.ville,
      pays: adr.pays || '',
      comm: adr.commentaire || '',
    };
    return adrForm;
  }

  static toFormValues(adrs: Adresse[]): AdrForm[] {
    const formValues: AdrForm[] = [];
    adrs.forEach(value => {
      formValues.push(Adresse.toFormValue(value));
    });
    return formValues;
  }

  /**
   * compare 2 addresses
   * @param adr1
   * @param adr2
   * @returns
   */
  static compare(adr1: Adresse, adr2: Adresse): boolean {
    return (
      adr1.adr1 === adr2.adr1 &&
      (adr1.adr2 ? adr1.adr2 : undefined) === (adr2.adr2 ? adr2.adr2 : undefined) &&
      adr1.cp === adr2.cp &&
      adr1.ville === adr2.ville &&
      adr1.pays === adr2.pays
    );
  }

  static forCompare(adr: Adresse): Adresse {
    return {
      adr1: adr.adr1,
      adr2: adr.adr2 ? adr.adr2 : undefined,
      cp: adr.cp,
      ville: adr.ville,
      pays: adr.pays,
    };
  }
}

/**
 * https://adresse.data.gouv.fr/api-doc/adresse
 */
export class AdresseApi {
  type!: string;
  geometry!: {
    type: string;
    coordinates: number[];
  };
  properties!: {
    city: string;
    street: string;
    district: string;
    citycode: string;
    context: string;
    id: string;
    importance: number;
    label: string;
    name: string;
    population: number;
    postcode: string;
    score: number;
    type: SearchType;
    x: number;
    y: number;
  };

  static getCoord(adr: AdresseApi): Coord {
    return {
      latitude: adr.geometry.coordinates[1],
      longitude: adr.geometry.coordinates[0],
    };
  }
}

export class Coord {
  latitude!: number;
  longitude!: number;
}

/**
 * Search enum type for geo api service
 */
export enum SearchType {
  /**
   * Numéro "à la plaque"
   */
  HOUSENUMBER = 'housenumber',
  /**
   * Position "à la voie", placé approximativement au centre de celle-ci
   */
  STREET = 'street',
  /**
   * lieu-dit
   */
  LOCALITY = 'locality',
  /**
   * numéro "à la commune"
   */
  MUNICIPALITY = 'municipality',
}
