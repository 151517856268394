import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {RouterModule} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';
import {AccountItemComponent} from '@utils/component/account-item/account-item.component';
import {AccountUpdateAddressesComponent} from '@utils/component/account-update-addresses/account-update-addresses.component';
import {AccountUpdateDocumentsComponent} from '@utils/component/account-update-documents/account-update-documents.component';
import {AccountUpdateEmailComponent} from '@utils/component/account-update-email/account-update-email.component';
import {AccountUpdateNotifsComponent} from '@utils/component/account-update-notifs/account-update-notifs.component';
import {AccountUpdatePhoneComponent} from '@utils/component/account-update-phone/account-update-phone.component';
import {AdrItemComponent} from '@utils/component/adr-item/adr-item.component';
import {DialogConfirmationComponent} from '@utils/component/confirmation-dialog/dialog-confirmation.component';
import {HeaderComponent} from '@utils/component/header/header.component';
import {NurseItemComponent} from '@utils/component/nurse-item/nurse-item.component';
import {SearchLocationComponent} from '@utils/component/search-location/search-location.component';
import {SliderRangeComponent} from '@utils/component/slider-range/slider-range.component';
import {NavBackDirective} from '@utils/directive/navBack.directive';
import {NavigationDirective} from '@utils/directive/navigation.directive';
import {ShowPickerDirective} from '@utils/directive/show-picker.directive';
import {DistancePipe} from '@utils/pipe/distance.pipe';
import {HourPipe} from '@utils/pipe/hour.pipe';
import {AdresseFormComponent} from '../component/adresse-form/adresse-form.component';
import {ButtonIconComponent} from '../component/button-icon/button-icon.component';
import {FileInputComponent} from '../component/file-input/file-input.component';
import {LabItemComponent} from '../component/lab-item/lab-item.component';
import {MapComponent, MarkerPopup} from '../component/map/map.component';
import {NotificationComponent} from '../component/notification/notification.component';
import {SectionComponent} from '../component/section/section.component';
import {SimpleHeaderComponent} from '../component/simple-header/simple-header.component';
import {DateAgoPipe} from '../pipe/date-ago.pipe';
import {SafizerPipe} from '../pipe/safizer.pipe';
import {MaterialModule} from './material.module';
import {SlideItemComponent} from '../component/slide-item/slide-item.component';
import {LoaderComponent} from '../component/loader/loader.component';

@NgModule({
  declarations: [
    SafizerPipe,
    DateAgoPipe,
    DistancePipe,
    SectionComponent,
    ButtonIconComponent,
    AdresseFormComponent,
    FileInputComponent,
    ShowPickerDirective,
    NavigationDirective,
    NavBackDirective,
    NotificationComponent,
    SimpleHeaderComponent,
    LabItemComponent,
    AdrItemComponent,
    MapComponent,
    MarkerPopup,
    HourPipe,
    SearchLocationComponent,
    HeaderComponent,
    SlideItemComponent,
    SearchLocationComponent,
    AccountItemComponent,
    LoaderComponent,
    NurseItemComponent,
    SliderRangeComponent,
    AccountUpdateAddressesComponent,
    AccountUpdateDocumentsComponent,
    AccountUpdateEmailComponent,
    AccountUpdatePhoneComponent,
    AccountUpdateNotifsComponent,
    DialogConfirmationComponent,
  ],
  imports: [FormsModule, ReactiveFormsModule, CommonModule, RouterModule, MaterialModule, TranslocoModule, MatSlideToggleModule],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    SafizerPipe,
    ShowPickerDirective,
    NavigationDirective,
    NavBackDirective,
    TranslocoModule,
    MaterialModule,
    SectionComponent,
    ButtonIconComponent,
    AdresseFormComponent,
    FileInputComponent,
    NotificationComponent,
    SimpleHeaderComponent,
    LabItemComponent,
    AdrItemComponent,
    MapComponent,
    HourPipe,
    SearchLocationComponent,
    HeaderComponent,
    SlideItemComponent,
    SearchLocationComponent,
    AccountItemComponent,
    NurseItemComponent,
    LoaderComponent,
    SliderRangeComponent,
    AccountUpdateAddressesComponent,
    AccountUpdateDocumentsComponent,
    AccountUpdateEmailComponent,
    AccountUpdatePhoneComponent,
    AccountUpdateNotifsComponent,
    DialogConfirmationComponent
  ],
  entryComponents: [MarkerPopup],
})
export class SharedModule {}
