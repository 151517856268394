import {Component, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {capSQLiteChanges, SQLiteDBConnection} from '@capacitor-community/sqlite';
import {App} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';
import {Keyboard, KeyboardResize} from '@capacitor/keyboard';
import {environment} from '@environment/environment';
import {EnvironmentTypeEnum} from '@environment/environment-type.enum';
import {DatabaseService} from '@utils/service/database.service';
import {LoaderService} from '@utils/service/loader.service';
import {NavigationService} from '@utils/service/navigation.service';
import {OnlineService} from '@utils/service/online.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title: string = 'kantysbio-app-mobile';

  enableLayout: boolean = [EnvironmentTypeEnum.DEV, EnvironmentTypeEnum.PREPROD].indexOf(environment.environment) !== -1; // on affiche en dev ou preprod uniquement
  env: EnvironmentTypeEnum = environment.environment;
  version: string = environment.version;
  enum: typeof EnvironmentTypeEnum = EnvironmentTypeEnum;

  footerRoutes: string[] = [
    '/patient',
    '/preleveur',
    '/patient/notification',
    '/preleveur/notification',
    '/patient/account',
    '/preleveur/account',
  ];

  isWeb: boolean = false;

  // init online service pour récupérer les infos au chargement de l’app
  constructor(
    public router: Router,
    public loader: LoaderService,
    private _online: OnlineService,
    private _db: DatabaseService,
    private nav: NavigationService,
    private ngZone: NgZone
  ) {
    if (Capacitor.isNativePlatform()) {
      new Promise<void>(async resolve => {
        await customElements.whenDefined('jeep-sqlite');

        this._db.initializePlugin().then(async ret => {
          let pluginInit: boolean = ret;
          const platform: string = this._db.platform;
          console.log(`plaform ${platform}`);
          if (platform === 'web') {
            this.isWeb = true;
            await customElements.whenDefined('jeep-sqlite');
            const jeepSqliteEl: HTMLJeepSqliteElement | null = document.querySelector('jeep-sqlite');
            if (jeepSqliteEl) {
              await this._db.initWebStore();

              console.log(`isStoreOpen ${await jeepSqliteEl.isStoreOpen()}`);
              console.log(`$$ jeepSqliteEl is defined}`);
            } else {
              console.log('$$ jeepSqliteEl is null');
            }
          }
          try {
            console.log(`going to create a connection`);
            const db: SQLiteDBConnection = await this._db.createConnection('kantysbio', false, 'no-encryption', 1);
            console.log(`db ${JSON.stringify(db)}`);
            await db.open();
            console.log(`after db.open`);
            const query: string = `
          CREATE TABLE IF NOT EXISTS test (
            id INTEGER PRIMARY KEY NOT NULL,
            name TEXT NOT NULL
          );
          `;
            console.log(`query ${query}`);

            const res: capSQLiteChanges = await db.execute(query);
            console.log(`res: ${JSON.stringify(res)}`);
            await this._db.closeConnection('kantysbio');
            console.log(`after closeConnection`);
          } catch (err) {
            console.log(`Error: ${err}`);
            pluginInit = false;
          }

          console.log('>>>> in App  SQLite Initialized ?', pluginInit);
        });
      });

      App.addListener('appStateChange', ({isActive}: {isActive: boolean}): void => {
        console.log('App state changed. Is active?', isActive);
      });

      App.addListener('appUrlOpen', data => {
        console.log('App opened with URL:', data.url);
        let url: string;
        if (data.url.startsWith('https://kantysbio')) {
          const split: string[] = data.url.split('?query=');
          url = data.url = split.length > 1 ? split[1] : '';
        } else {
          url = data.url.replace(/.+?:\/\/localhost/, '').replace(/.+?app\.kantysbio\.fr/, '');
        }
        console.log('navigate to ' + url);

        window.location.href = window.origin + url;
      });

      App.addListener('appRestoredResult', data => {
        console.log('Restored state:', data);
      });

      App.addListener('backButton', () => this.backHandle());

      if (Capacitor.getPlatform() === 'ios') {
        Keyboard.setResizeMode({mode: KeyboardResize.Native});
      }
    }
  }

  backHandle(): void {
    this.ngZone.run(() => {
      if (!this.nav.canGoBack()) {
        App.minimizeApp();
      } else {
        this.nav.back();
      }
    });
  }
}
