<div class="slider" [class.disabled]="isDisabled">
  <div class="bar" (touchmove)="handlerSliding($event)" (panmove)="handlerSliding($event)">
    <span class="left-handle active"
          [style.left.%]="handlerX[handlerIndex.left]" (touchstart)="setHandlerActive($event, handlerIndex.left)" (panstart)="setHandlerActive($event, handlerIndex.left)">
      <span *ngIf="!hideTooltip" class="handle-tooltip">{{currentValues[handlerIndex.left] + 'h'}}</span>
    </span>
    <div class="filler">
      <div class="step-indicators">
        <span *ngFor="let stepPos of stepIndicatorPositions" [style.left.px]="stepPos"></span>
      </div>
      <span *ngIf="multiRange" [style.left.%]="handlerX[0]" [style.width.%]="handlerX[handlerIndex.right] - handlerX[handlerIndex.left]"></span>
      <span *ngIf="!multiRange" [style.left.%]="0" [style.width.%]="handlerX[0]"></span>
    </div>
    <span *ngIf="multiRange" class="right-handle active"
          [style.left.%]="handlerX[handlerIndex.right]" (touchstart)="setHandlerActive($event, handlerIndex.right)"
          (panstart)="setHandlerActive($event, handlerIndex.right)">
      <span *ngIf="!hideTooltip" class="handle-tooltip">{{currentValues[handlerIndex.right] + 'h'}}</span>
    </span>
  </div>
  <div class="values" *ngIf="!hideValues">
    <span>{{initValues[handlerIndex.left]+ 'h'}}</span>
    <span>{{initValues[handlerIndex.right] +'h'}}</span>
  </div>
</div>
