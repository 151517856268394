import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {NavigationService} from '@utils/service/navigation.service';

@Directive({
  selector: '[navigation]',
})
export class NavigationDirective implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() navigation!: string | any[];
  @Input() relativeTo?: ActivatedRoute | null = this._route;
  @Input() queryParams?: Params | null;

  constructor(private _elemRef: ElementRef, private nav: NavigationService, private _route: ActivatedRoute) {}

  ngOnInit(): void {
    if (this._elemRef.nativeElement.localName === 'a') {
      if (typeof this.navigation === 'string') {
        this.navigation = [this.navigation];
      }
      const url: string = this.nav.createUrl(this.navigation, {relativeTo: this.relativeTo, queryParams: this.queryParams});
      this._elemRef.nativeElement.href = url;
    }
  }

  @HostListener('click')
  onClick(): boolean {
    if (typeof this.navigation === 'string') {
      this.navigation = [this.navigation];
    }

    this.nav.navigate(this.navigation, {relativeTo: this.relativeTo, queryParams: this.queryParams});
    // return false to prevent Default (useful for link)
    return false;
  }
}
