import {Injectable} from '@angular/core';
import {BehaviorSubject, debounceTime, filter} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private open: number = 0;
  private unlockTime: number = 15000;

  constructor() {
    // backup if stuck in loop
    this.show$.pipe(debounceTime(this.unlockTime), filter(Boolean)).subscribe(() => {
      this.open = 0;
      this.show$.next(this.open !== 0);
    });
  }

  show(): void {
    this.open += 1;
    this.show$.next(this.open !== 0);
  }

  hide(): void {
    this.open = Math.max(this.open - 1, 0);
    this.show$.next(this.open !== 0);
  }
}
