<div class="d-flex h-100 align-items-start flex-column">
  <app-simple-header titre="account.username.tel" [back]="true" class="w-100"></app-simple-header>

  <div class="mb-auto w-100" *transloco="let t; read: 'account.username'">
    <app-section titre="account.username.phone_change">
      <form [formGroup]="username">
        <mat-form-field appearance="fill">
          <mat-icon matPrefix class="grey">phone</mat-icon>
          <mat-label>{{t('tel')}}</mat-label>
          <input type="tel" matInput
                 formControlName="phone"
                 autocomplete="tel"
                 [pattern]="telPattern">
          <mat-error *ngIf="username.get('phone')?.hasError('phoneExists')">
            {{t('tel_unavailable')}}
          </mat-error>
          <mat-error *ngIf="username.hasError('identifierRequired')">
            {{t('loginExists')}}
          </mat-error>
        </mat-form-field>
      </form>
    </app-section>
  </div>

  <div class="button-footer mb-2">
    <app-button-icon (buttonClick)="validate()" text="app.save" [disabled]="username.pending || username.invalid">
    </app-button-icon>
  </div>
</div>