import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MyKeycloakService} from '@utils/service/my-keycloak.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent {
  private redirectUri: string = '/login';

  constructor(private keycloak: MyKeycloakService, private _route: ActivatedRoute) {
    const redirect: string = _route.snapshot.queryParams['redirectUri']?.replace(window.location.origin, '');

    // to avoid cycle on logout page
    if (redirect && redirect !== '/logout') {
      this.redirectUri = redirect;
    }

    keycloak.logout(this.redirectUri);
  }
}
