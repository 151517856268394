<div>
  <button mat-flat-button class="w-100 p-0" (click)="buttonClick.emit(navigate)">
    <div class="item d-flex align-items-center" [class.last]="last">
      <div class="icon flex-shrink-1">
        <img *ngIf="icon" [src]="icon | safizer">
      </div>
      <div class="px-3 text flex-grow-1 d-flex flex-column">
        <div class="d-flex justify-content-between">
          <span>{{title}}</span>
        </div>
        <span class="subtitle">
          {{subtitle}}
        </span>
      </div>
      <div class="icon">
        <mat-icon [inline]="true">arrow_forward</mat-icon>
      </div>
    </div>
  </button>
</div>