/* eslint-disable @typescript-eslint/no-explicit-any */
export class LaboSearch {
  /**
   * Search by name, address 1, address 2 and city
   */
  search?: SearchValue;
  /**
   * Search by city
   */
  city?: SearchValue;
  /**
   * Search by postal code
   */
  postalCode?: SearchValue;

  convertToRequest(): string[] {
    const request: string[] = [];
    if (this.search) {
      request.push('search' + this.toRequest(this.search));
    }
    if (this.city) {
      request.push('city' + this.toRequest(this.city));
    }
    if (this.postalCode) {
      request.push('postalCode' + this.toRequest(this.postalCode));
    }
    return request;
  }

  setValue(param: string, value: string, operator: string = ':'): void {
    (this as any)[param] = {value, operator};
  }

  private toRequest(search: SearchValue): string {
    return (search.operator ? search.operator : ':') + search.value;
  }
}

export class NurseSearch {
  /**
   * Search by name, address 1, address 2 and city
   */
  search?: SearchValue;
  /**
   * Search by city
   */
  city?: SearchValue;
  /**
   * Search by postal code
   */
  postalCode?: SearchValue;

  convertToRequest(): string[] {
    const request: string[] = [];
    if (this.search) {
      request.push('search' + this.toRequest(this.search));
    }
    if (this.city) {
      request.push('city' + this.toRequest(this.city));
    }
    if (this.postalCode) {
      request.push('postalCode' + this.toRequest(this.postalCode));
    }
    return request;
  }

  setValue(param: string, value: string, operator: string = ':'): void {
    (this as any)[param] = {value, operator};
  }

  private toRequest(search: SearchValue): string {
    return (search.operator ? search.operator : ':') + search.value;
  }
}

export class CheckSearch {
  /**
   * Search by libelle and description
   */
  search?: SearchValue;

  convertToRequest(): string[] {
    const request: string[] = [];
    if (this.search) {
      request.push('search' + this.toRequest(this.search));
    }
    return request;
  }

  setValue(param: string, value: string, operator: string = ':'): void {
    (this as any)[param] = {value, operator};
  }

  private toRequest(search: SearchValue): string {
    return (search.operator ? search.operator : ':') + search.value;
  }
}

interface SearchValue {
  value: string;
  operator?: SearchOperator;
}

type SearchOperator = ':' | '~' | '>' | '<';
