export enum RegexPatternValidatorEnum {
  ALPHANUM = '[\\w\\s-]*',
  ALPHA = '[A-Za-z]*',
  NUM = '\\d*',
  TEL = '^(?:(?:\\+|00)\\d{2,3}|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}$',
  // eslint-disable-next-line max-len
  EMAIL = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  // eslint-disable-next-line max-len
  SITE = '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$',
  /**
   * 8 char minimum
   * 1 maj
   * 1 min
   * 1 num
   * 1 spe
   * no space !
   */
  // PASSWORD = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^A-Za-z\\d\\r\\n])\\S{8,}$',
  USERNAME = '(?:(^(?:(?:\\+|00)\\d{2,3}|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}$)|(^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$))',
  SECU = '[12][0-9]{2}(0[1-9]|1[0-2])(2[AB]|[0-9]{2})[0-9]{3}[0-9]{3}([0-9]{2})?',
}
