<div class="h-100 d-flex flex-column grey">
  <!-- HEADER -->
  <div class="full-size position-relative d-flex justify-content-end header-container">
    <img class="header" src="assets/img/fond-accueil-v2.jpeg" alt="Kantys Bio">
    <img class="logo" src="assets/img/kantys-bio.png" alt="Kantys Bio">
  </div>

  <!-- FORM -->
  <div class="d-flex flex-column justify-content-center align-items-center mb-auto">
    <div>
      <h1 class="title mb-0">{{'login.title' | transloco}}</h1>
      <p class="subtitle">{{'login.subtitle' | transloco}}</p>
    </div>
    <form class="w-100" (submit)="login(); false" action="#">
      <div class="left-inner-addon mt-3">
        <input id="username" class="login-field" placeholder="{{'login.username' | transloco}}" type="text"
               [class.error]="error"
               name="username"
               [formControl]="username"
               tabindex="1" autocomplete="username">
        <span class="material-icons"> person </span>
      </div>
      <div class="left-inner-addon my-3">
        <input id="password" class="login-field" placeholder="{{'login.password' | transloco}}"
               [class.error]="error"
               [type]="!visibility ? 'password' : 'text'"
               name="password"
               [formControl]="password"
               autocomplete="current-password" tabindex="2">
        <span class="material-icons"> lock </span>
        <button mat-icon-button class="visibility" (click)="visibility = !visibility" type="button">
          <mat-icon>{{visibility ? 'visibility' : 'visibility_off'}}</mat-icon>
        </button>
      </div>
      <!-- ERROR -->
      <div class="d-flex justify-content-center error-container mb-3">
        <div class="alert alert-${message.type}" *ngIf="error">
          <span class="message-text">{{'login.'+ error | transloco}}</span>
        </div>
      </div>

      <button mat-flat-button class="submit" color="primary" tabindex="3">{{'login.login' | transloco}}</button>
    </form>
  </div>

  <div class="align-self-center my-3">
    <a class=" forgot" [navigation]="['/password/reset']">{{'login.forgot_password' | transloco}}</a>
  </div>

  <div>
    <p class="register">{{'login.no_account' | transloco}}
      <a [navigation]="['/register']">{{'login.register' | transloco}}</a>
    </p>
  </div>
</div>
