<div class="file-input-container d-flex flex-column grey" [class.py-2]="readOnly" [class.my-3]="!noMargin">
  <!-- add part -->
  <div class="d-flex justify-content-between align-items-center g-0 my-2" *ngIf="!readOnly || text">
    <div class="d-flex flex-column mx-2 me-auto">
      <span class="bold" [class.small]="placeholder && !files?.length">{{text | transloco}}</span>
      <span *ngIf="placeholder && !files?.length">{{placeholder | transloco}}</span>
    </div>

    <button mat-icon-button class="icon mx-2" *ngIf="!readOnly && showCamera"
            aria-label="Prendre une photo"
            (click)="takePicture()">
      <mat-icon>photo_camera</mat-icon>
    </button>

    <button mat-icon-button class="icon me-3 ms-1" *ngIf="!readOnly"
            (click)="fileUpload.click()">
      <mat-icon>add</mat-icon>
    </button>


  </div>

  <div class="d-flex align-items-center file me-2" *ngFor="let file of files">
    <img class="img" src="/assets/svg/files.svg" alt="img">
    <div class="d-flex me-auto" [ngClass]="hybrid ? 'col-7' : 'col-9'">
      <span class="name">{{removeExtension(file.doc?.filename)}}</span>
      <span class="extension">{{getExtension(file.doc?.filename)}}</span>
    </div>
    <button mat-icon-button class="icon icon-dl ms-1" [ngClass]="hybrid ? 'me-4' :'me-3'" *ngIf="readOnly || hybrid"
            (click)="download(file)">
      <mat-icon>download</mat-icon>
    </button>
    <button mat-icon-button class="icon icon-rm me-3 ms-1" *ngIf="!readOnly"
            (click)="remove(file)">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <input type="file" class="file-input" (change)="onFileSelected($event)" [attr.multiple]="multiple ? true : undefined"
         #fileUpload>
</div>
