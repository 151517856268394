import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Labo} from '@utils/dto/labo/labo';

@Component({
  selector: 'app-account-item',
  templateUrl: './account-item.component.html',
  styleUrls: ['./account-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountItemComponent implements OnInit {
  @Input() icon?: string;
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() last?: boolean;
  @Input() navigate?: string;
  @Output() buttonClick: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}
}
