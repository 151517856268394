import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Camera, CameraResultType, Photo} from '@capacitor/camera';
import {Capacitor} from '@capacitor/core';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {translate, TranslocoService} from '@ngneat/transloco';
import {DocumentService} from '@service/document.service';
import {FileDto, toBase64, urltoFile} from '@utils/dto/document/file';
import {SnackbarService} from '@utils/service/snackbar.service';
import {without} from 'lodash';
import {firstValueFrom} from 'rxjs';
import {DialogConfirmationComponent} from '../confirmation-dialog/dialog-confirmation.component';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInputComponent {
  @Input() text!: string;
  @Input() placeholder!: string;

  @Input() files?: FileDto[];
  // read only
  @Input() readOnly: boolean = false;
  // read & write
  @Input() hybrid: boolean = false;
  @Input() noMargin: boolean = false;

  @Input() multiple: boolean = true;
  @Input() camera: boolean = true;

  @Output() fileChange: EventEmitter<FileDto[]> = new EventEmitter<FileDto[]>();

  showCamera: boolean = false;

  private maxSize: number = 10485760;
  private allowedType: string[] = ['application/pdf', 'image/jpeg', 'image/png'];

  constructor(
    public dialog: MatDialog,
    private _cd: ChangeDetectorRef,
    private _doc: DocumentService,
    private toastr: SnackbarService,
    private _transloco: TranslocoService
  ) {
    if (Capacitor.getPlatform() !== 'ios' && this.camera) {
      this.showCamera = true;
    }
  }

  onFileSelected($event: any): void {
    if ($event.target.files) {
      const files: FileDto[] = [];
      let someTooBig: boolean = false;
      let someNotAllowed: boolean = false;
      Array.from($event.target.files).forEach((file: any): void => {
        if (file.size > this.maxSize) {
          someTooBig = true;
        } else if (!file.type || !this.allowedType.includes(file.type)) {
          someNotAllowed = true;
        } else {
          files.push({
            file,
            doc: {
              filename: file.name,
            },
          });
        }
      });

      if (someTooBig) {
        this.toastr.showWarning('file_input.too_big', {config: {duration: 2000}});
      }

      if (someNotAllowed) {
        this.toastr.showWarning('file_input.not_allowed', {config: {duration: 2000}});
      }

      if (this.multiple) {
        this.files = (this.files || []).concat(files);
      } else {
        this.files = files;
      }
      this._cd.detectChanges();
      this.fileChange.emit(this.files);
    }
  }

  remove(file: FileDto): void {
    const dialogRef: MatDialogRef<DialogConfirmationComponent> = this.dialog.open(DialogConfirmationComponent, {
      data: {
        title: 'file_input.dialog.title',
        content: 'file_input.dialog.content',
      },
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        if (this.files) {
          if (file.doc?.id) {
            this._doc.delete(file.doc.id);
          }
          this.files = without(this.files, file);

          this.fileChange.emit(this.files);
          this._cd.detectChanges();
        }
      }
    });
  }

  async takePicture(): Promise<void> {
    try {
      const image: Photo = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        promptLabelHeader: this._transloco.translate('camera.header'),
        promptLabelCancel: this._transloco.translate('camera.cancel'),
        promptLabelPhoto: this._transloco.translate('camera.photo'),
        promptLabelPicture: this._transloco.translate('camera.picture'),
      });

      if (image.base64String) {
        const imgUrl: string = `data:image/${image.format};base64,${image.base64String}`;
        const file: File = await urltoFile(imgUrl, `capture_${Date.now()}.${image.format}`, `image/${image.format}`);
        const files: FileDto[] = [];
        if (file.size < this.maxSize) {
          files.push({
            file,
            doc: {
              filename: file.name,
            },
          });
        } else {
          this.toastr.showWarning('file_input.too_big', {config: {duration: 2000}});
        }

        if (this.multiple) {
          this.files = (this.files || []).concat(files);
        } else {
          this.files = files;
        }
      }

      this._cd.detectChanges();
      this.fileChange.emit(this.files);
    } catch (error) {
      console.debug('User cancelled photos app');
    }
  }

  async download(file: FileDto): Promise<void> {
    let fblob: Blob | undefined = undefined;
    let fname: string = 'Kantysbio_document';
    if (file.file) {
      fblob = file.file;
      fname = file.file.name;
    } else if (file.doc?.id) {
      fblob = await firstValueFrom(this._doc.get(file.doc?.id));
      if (file.doc.filename) {
        fname = file.doc.filename;
      }
    }

    if (fblob) {
      if (!Capacitor.isNativePlatform()) {
        const a: HTMLAnchorElement = document.createElement('a');
        const url: string = await toBase64(fblob);
        a.href = url;
        a.download = fname;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      } else {
        const data: string = (await toBase64(fblob)).replace('data:', '').replace(/^.+,/, '');
        try {
          if (Capacitor.getPlatform() === 'android') {
            await Filesystem.writeFile({
              path: `file:///storage/emulated/0/Download/${fname}`,
              recursive: true,
              data, // your data to write (ex. base64)
            });

            this.toastr.showSuccess(translate('file_input.file_saved_android', {path: `Téléchargements/${fname}`}), {
              config: {duration: 3000},
              doNotUseTranslateService: true,
            });
          } else if (Capacitor.getPlatform() === 'ios') {
            await Filesystem.writeFile({
              path: fname,
              recursive: true,
              data, // your data to write (ex. base64)
              directory: Directory.Data,
            });

            this.toastr.showSuccess(translate('file_input.file_saved_ios', {fname}), {
              config: {duration: 3000},
              doNotUseTranslateService: true,
            });
          }
        } catch (error) {
          console.error('error writing file', error);
        }
      }
    }
  }

  /**
   * extract extension from filename (with .)
   * @param fname
   * @returns
   */
  getExtension(fname?: string): string {
    return fname ? fname.slice(((fname.lastIndexOf('.') - 1) >>> 0) + 1) : '';
  }

  /**
   * remove extension from filename
   * @param fname
   * @returns
   */
  removeExtension(fname?: string): string {
    return fname ? fname.slice(0, ((fname.lastIndexOf('.') - 1) >>> 0) + 1) : '';
  }
}
