<ng-container *ngIf="enableLayout">
  <div class="layout" [ngClass]="env + '-layout'">
    <div class="version">
      {{env | titlecase}}: {{version}}
    </div>
  </div>
</ng-container>
<div [class.h-100]="!footerRoutes.includes(router.url)">
  <router-outlet></router-outlet>
</div>
<jeep-sqlite *ngIf="isWeb"></jeep-sqlite>
<app-footer *ngIf="footerRoutes.includes(router.url)"></app-footer>

<app-loader *ngIf="loader.show$ | async"></app-loader>
