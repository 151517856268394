import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {App} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';
import {PushNotifications} from '@capacitor/push-notifications';
import {environment} from '@environment/environment';
import {Message} from '@utils/dto/message';
import {RegistrationRequest} from '@utils/dto/notification/registration-request';
import {StorageKey} from '@utils/enums/storage-key.enum';
import {StorageService} from '@utils/service/storage.service';
import {KeycloakService} from 'keycloak-angular';
import {DateTime} from 'luxon';
import {firstValueFrom, Observable} from 'rxjs';

const FIREBASE_TOKEN: string = 'fbreg';
const FIREBASE_EXPIRY_TOKEN: string = 'fbreg_expiry';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  isNew?: boolean;
  private _endpoint: string = environment.api + '/notifs';
  private lastCheck: Date = new Date(Date.now());
  private _key: string = StorageKey.LAST_CHECK;

  constructor(private _http: HttpClient, private _storage: StorageService, private _keycloak: KeycloakService) {
    this.initLastCheck();
  }

  async initLastCheck(): Promise<void> {
    const res: string = await this._storage.get<string>(this._key);
    if (res) {
      this.lastCheck = new Date(res);
    }
  }

  updateLastCheck(): void {
    this.lastCheck = new Date(Date.now());
    this._storage.set(this._key, this.lastCheck);
    this.updateNewNotif();
  }

  getLastCheck(): Date {
    return this.lastCheck;
  }

  getLatest(size?: number): Observable<Message[]> {
    let params: HttpParams = new HttpParams();

    if (size) {
      params = params.set('size', size);
    }

    return this._http.get<Message[]>(this._endpoint + '/latest', {params});
  }

  updateNewNotif(): void {
    this.checkNewNotification().subscribe(res => (this.isNew = res));
  }

  checkNewNotification(): Observable<boolean> {
    const params: HttpParams = new HttpParams().set('since', this.lastCheck.toUTCString());
    return this._http.get<boolean>(this._endpoint + '/is-new', {params});
  }

  registration(request: RegistrationRequest): Observable<void> {
    return this._http.post<void>(this._endpoint + '/registrations', request);
  }

  async initNotification(): Promise<void> {
    if (Capacitor.isPluginAvailable('PushNotifications')) {
      await PushNotifications.removeAllListeners();

      PushNotifications.addListener('registration', async token => {
        if (!token) {
          console.error('No token found', token);
          return;
        }

        if (!(await this._shouldTriggerRegistration())) {
          console.warn('Not registering, already did for this token', token.value);
          return;
        }
        // // automatically subscribe to some topics (async)
        // const topics: string[] = environment.firebase.topics_autosubscribe;
        // if (topics) {
        //   topics.forEach(topic => store.dispatch(new SubscribeToTopic(currentToken, topic, environment.firebase.iidToken)));
        // }

        await this._storage.set(FIREBASE_TOKEN, token.value);
        await this._storage.set(FIREBASE_EXPIRY_TOKEN, DateTime.now().plus({day: 5}).toMillis());

        // store registration for the kantys user in the backend (async)
        firstValueFrom(this.registration({tokenId: token.value}));
      });

      PushNotifications.addListener('registrationError', error => {
        console.error(error);
      });

      if ((await this._shouldTriggerRegistration()) && (await this._keycloak.isLoggedIn())) {
        await PushNotifications.requestPermissions();
        PushNotifications.register();
      }
    }
  }

  private async _shouldTriggerRegistration(): Promise<boolean> {
    const firebaseTokenPresent: boolean = await this._storage.has(FIREBASE_TOKEN);
    const expiryStoragePresent: boolean = await this._storage.has(FIREBASE_EXPIRY_TOKEN);
    const expiryStorage: number = await this._storage.get<number>(FIREBASE_EXPIRY_TOKEN, DateTime.now().minus({minutes: 10}).toMillis());
    const now: number = DateTime.now().toMillis();
    return !firebaseTokenPresent || !expiryStoragePresent || now > expiryStorage;
  }
}
