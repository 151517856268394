import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environment/environment';
import {Labo} from '@utils/dto/labo/labo';
import {Nurse} from '@utils/dto/nurse/nurse';
import {LaboSearch, NurseSearch} from '@utils/dto/utils/search';
import {Searchable} from '@utils/dto/utils/searchable.interface';
import {map, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class NurseService {
  private endpoint: string = environment.api + '/nurses';
  constructor(private http: HttpClient) {}

  get(id: string): Observable<Nurse>{
    return this.http.get(this.endpoint + "/" + id);
  }

  findNearest(lat: string | number, lon: string | number): Observable<Nurse[]> {
    const params: HttpParams = new HttpParams().set('lat', lat.toString()).set('lon', lon.toString());
    return this.http.get<Labo[]>(this.endpoint + '/find-nearest', {params});
  }

  search(search: NurseSearch): Observable<Nurse[]> {
    const params: HttpParams = new HttpParams().set('q', search.convertToRequest().join(','));
    return this.http.get<Searchable<Nurse>>(this.endpoint, {params}).pipe(map((result: Searchable<Nurse>): Nurse[] => result.content));
  }
}
