<div [class.full-size]="fullSize">
  <button mat-flat-button class="w-100 p-0" (click)="buttonClick.emit()">
    <div class="item d-flex align-items-center" [class.last]="last">
      <div class="icon flex-shrink-1">
        <img *ngIf="home" src="assets/svg/home.svg">
        <img *ngIf="!home" src="assets/svg/address.svg">
      </div>
      <div class="px-3 text me-auto">
        <span *ngIf="home">{{'address.home' | transloco}}</span>
        <span *ngIf="!home">
          {{
          index ?
          ('address.other_index' | transloco:{index}) :
          ('address.other' | transloco)
          }}
        </span>
        <br>
        <span class="adresse"
              [title]="adr.adr1 + ',' + adr.cp + ' ' + adr.ville">
          {{adr.adr1}}, {{adr.cp}} {{adr.ville}}
        </span>
      </div>
      <div class="icon">
        <mat-icon [inline]="true">arrow_forward</mat-icon>
      </div>
    </div>
  </button>
</div>
