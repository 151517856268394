import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '@service/user.service';
import {RegexPatternValidatorEnum} from '@utils/enums/regex.enum';
import {NavigationService} from '@utils/service/navigation.service';
import {firstValueFrom, take} from 'rxjs';

@Component({
  selector: 'app-account-update-phone',
  templateUrl: './account-update-phone.component.html',
  styleUrls: ['./account-update-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AccountUpdatePhoneComponent implements OnInit {
  telPattern: RegexPatternValidatorEnum = RegexPatternValidatorEnum.TEL;
  username!: FormGroup;
  constructor(
    private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _nav: NavigationService,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this.username = this._fb.group(
      {
        email: [null],
        phone: [null, {asyncValidators: [this._userService.phoneValidator()], updateOn: 'blur'}],
      },
      {validators: [this.identifierRequired]}
    );
    this._userService
      .get()
      .pipe(take(1))
      .subscribe(user => {
        this.username.get('email')?.setValue(user.email);
        this.username.get('phone')?.setValue(user.phone);
      });
  }

  async validate(): Promise<void> {
    if (this.username?.valid) {
      await firstValueFrom(this._userService.updatePhone(this.username.get('phone')?.value ? this.username.get('phone')?.value : ''));
      this._nav.navigate(['..'], {relativeTo: this._route});
    } else {
      this.username.get('phone')?.markAllAsTouched();
    }
  }

  identifierRequired(control: AbstractControl): ValidationErrors | null {
    const email: string = control?.get('email')?.value;
    const tel: boolean = control?.get('phone')?.value;

    if (!tel && !email) {
      control?.get('phone')?.setErrors({identifierRequired: true});
      return {identifierRequired: true};
    }
    control?.get('phone')?.setErrors(null);
    return null;
  }
}
