import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NotificationService} from '@service/notification.service';
import {DatabaseService} from '@utils/service/database.service';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {AppRoutingModule} from './app-routing.module';

import {registerLocaleData} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {ReactiveFormsModule} from '@angular/forms';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {Router} from '@angular/router';
import {LoginComponent} from '@page/login/login/login.component';
import {LogoutComponent} from '@page/login/logout/logout.component';
import {initializeKeycloak} from '@security/keycloak.initializer';
import {errorInterceptorProvider} from '@utils/interceptor/error.interceptor';
import {SharedModule} from '@utils/module/shared.module';
import {AppComponent} from './app.component';
import {TranslocoRootModule} from './transloco-root.module';
import {FooterComponent} from './utils/component/footer/footer.component';
import {MyKeycloakService} from '@utils/service/my-keycloak.service';

registerLocaleData(localeFr);

const initializeApp = (
  keycloakService: KeycloakService,
  httpClient: HttpClient,
  router: Router,
  myKeycloakService: MyKeycloakService,
  notificationService: NotificationService
) => {
  return async (): Promise<void> => {
    const keycloak: boolean = await initializeKeycloak(keycloakService, httpClient, router, myKeycloakService)();
    if (keycloak) {
      await notificationService.initNotification();
    }
  };
};

@NgModule({
  declarations: [AppComponent, FooterComponent, LoginComponent, LogoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    KeycloakAngularModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [KeycloakService, HttpClient, Router, MyKeycloakService, NotificationService],
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },
    errorInterceptorProvider,
    DatabaseService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
