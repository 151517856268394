// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import packageJson from '../../package.json';
import {EnvironmentTypeEnum} from './environment-type.enum';

// eslint-disable-next-line @typescript-eslint/typedef
export const environment = {
  environment: EnvironmentTypeEnum.LOCAL,
  version: packageJson.version,
  // api: 'https://kantysbio-bff-mobile.its-future.com/api',
  api: 'http://bff-mobile.kantysbio.local/api', // Url d'appel vers le server
  // api: 'http://localhost:8081/api',
  firebase: {
    topics_autosubscribe: [],
  },
  keycloak: {
    // Réglage Keycloak
    realm: 'kantysbio', // Nom du realm (Nom de domaine)
    client: 'kantysbio-mobile-client', // Nom du client
    url: 'http://auth.kantysbio.local/', // Url de keycloak pour l'authentification, le slash final est important !
    // url: 'https://kantysbio-id.its-future.com/',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
