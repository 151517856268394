import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '@service/notification.service';
import {NavigationService} from '@utils/service/navigation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FooterComponent {

  constructor(
    public router: Router,
    public _route: ActivatedRoute,
    public notifService: NotificationService,
    private _nav: NavigationService
  ) {
    this.notifService.updateNewNotif();
  }
  notification(): void {
    const url_string: string[] = this.router.url.split('/');
    if (url_string.length > 1) {
      this._nav.navigate([url_string[1], 'notification']);
    }
  }
  account(): void {
    const url_string: string[] = this.router.url.split('/');
    if (url_string.length > 1) {
      this._nav.navigate([url_string[1], 'account']);
    }
  }
}
