import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DocumentService} from '@service/document.service';
import {UserService} from '@service/user.service';
import {DocumentDto} from '@utils/dto/document/document';
import {FileDto} from '@utils/dto/document/file';
import {DocumentTypeEnum} from '@utils/enums/document-type.enum';
import {NavigationService} from '@utils/service/navigation.service';
import {Dictionary, groupBy} from 'lodash';
import {firstValueFrom, take} from 'rxjs';

@Component({
  selector: 'app-account-update-documents',
  templateUrl: './account-update-documents.component.html',
  styleUrls: ['./account-update-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountUpdateDocumentsComponent implements OnInit {
  files: Map<DocumentTypeEnum, FileDto[]> = new Map<DocumentTypeEnum, FileDto[]>();

  DocumentTypeEnum: typeof DocumentTypeEnum = DocumentTypeEnum;

  loading: boolean = false;

  constructor(
    private _userService: UserService,
    private _nav: NavigationService,
    private _doc: DocumentService,
    private _cd: ChangeDetectorRef,
    protected _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._doc
      .getUserDocumentList()
      .pipe(take(1))
      .subscribe(documents => {
        const dico: Dictionary<DocumentDto[]> = groupBy(documents, doc => doc.documentType);
        for (const key in dico) {
          this.files.set(
            key as DocumentTypeEnum,
            dico[key].map((doc): FileDto => {
              return {doc};
            })
          );
        }
        this._cd.detectChanges();
      });
  }

  fileChange(key: DocumentTypeEnum, files: FileDto[]): void {
    this.files.set(key, files);
  }

  async validate(): Promise<void> {
    this.loading = true;

    const docsPromise: Promise<DocumentDto>[] = [];
    this.files.forEach((documents: FileDto[], key: DocumentTypeEnum) => {
      if (documents) {
        documents.forEach(file => {
          if (file.file && !file.doc?.id) {
            docsPromise.push(firstValueFrom(this._doc.upload(file.file, key)));
          }
        });
      }
    });

    await Promise.all(docsPromise);
    this.loading = false;
    this._nav.navigate(['..'], {relativeTo: this._route});
  }
}
