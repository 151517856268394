import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environment/environment';
import {AssociationModeEnum} from '@response/user.response';
import {Timeslot} from '@utils/dto/labo/schedule';
import {Sample, SampleCountByDate, SampleUser, SampleWithLabo} from '@utils/dto/sample/sample';
import {SampleForm} from '@utils/dto/sample/sample-form';
import {Tube} from '@utils/dto/sample/tube';
import {SampleStatusEnum} from '@utils/enums/sample-status.enum';
import {DateTime} from 'luxon';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SampleService {
  private endpoint: string = environment.api + '/samples';

  constructor(private http: HttpClient) {}

  get(uuid: string): Observable<Sample> {
    return this.http.get(this.endpoint + '/' + uuid);
  }

  getWithLabo(uuid: string): Observable<SampleWithLabo> {
    return this.http.get(this.endpoint + '/w-labo/' + uuid);
  }

  create(sample: Sample): Observable<Sample> {
    return this.http.post(this.endpoint + '/create', sample);
  }

  createForNow(ipp: string, sample: Sample): Observable<Sample> {
    return this.http.post(this.endpoint + '/' + ipp + '/create-for-now', sample);
  }

  cancelSample(updateid: string): Observable<Sample> {
    return this.updateStatus(updateid, SampleStatusEnum.CANCEL);
  }

  updateStatus(id: string, status: SampleStatusEnum): Observable<Sample> {
    const params: HttpParams = new HttpParams().set('status', status);
    return this.http.post(this.endpoint + '/update-status/' + id, null, {params});
  }

  updateAllToStatus(ids: string[], status: SampleStatusEnum): Observable<Sample[]> {
    const params: HttpParams = new HttpParams().set('status', status);
    return this.http.post<Sample[]>(this.endpoint + '/update-status/', ids, {params});
  }

  updateForm(form: SampleForm): Observable<SampleForm> {
    return this.http.post<SampleForm>(this.endpoint + '/form', form);
  }

  saveTubes(id: string, tubes: Tube[]): Observable<Tube[]> {
    return this.http.post<Tube[]>(this.endpoint + `/${id}/tubes`, tubes);
  }

  /**
   * Find all sample for the connected user
   * @returns
   */
  findAll(): Observable<Sample[]> {
    return this.http.get<Sample[]>(this.endpoint + '/find');
  }

  /**
   * Find all coming sample for the connected user
   * @returns
   */
  findAllComing(): Observable<Sample[]> {
    return this.http.get<Sample[]>(this.endpoint + '/find/coming');
  }

  /**
   * Find all past or canceled sample for the connected user
   * @returns
   */
  findAllPastOrCanceled(): Observable<Sample[]> {
    return this.http.get<Sample[]>(this.endpoint + '/find/past');
  }

  findAllByPreleveurAndSampleDate(sampleDate: DateTime): Observable<SampleUser[]> {
    const params: HttpParams = new HttpParams().set('sampleDate', sampleDate.toISODate());
    return this.http.get<SampleUser[]>(this.endpoint + '/preleveur', {params});
  }

  getSampleCountByDateAndStartTime(laboId: string, dates: string[]): Observable<SampleCountByDate[]> {
    const params: HttpParams = new HttpParams().set('dates', dates.join(','));
    return this.http.get<SampleCountByDate[]>(this.endpoint + `/${laboId}/counts`, {params});
  }

  getSampleTimeslot(lat: string, lng: string, dates: string[], searchMode: AssociationModeEnum): Observable<Timeslot[]> {
    const params: HttpParams = new HttpParams().set('lat', lat).set('lon', lng).set('dates', dates.join(',')).set('searchMode', searchMode);
    return this.http.get<Timeslot[]>(this.endpoint + '/timeslots', {params});
  }
}
